module.exports = {
  commen: {
    userlogin: "Ingresar",
    steemAccoutPlaceHolder: "Steem Account",
    hiveAccountPlaceHolder: "Hive account",
    login:" Ingresar ",
    loginByKeychain:" Ingresar por Keychain ",
    loging:" Ingresando ... ",
    cancel:" Cancelar ",
    confirm:" Confirmar ",
    add:" Añadir ",
   update:" Actualizar " ,
    crowdstaking: "Staking",

    apy: "APR",
    withdraw: "Cosechar",
    balance: "Balance",
     approveContrac: "Aprobar Contrato",
    max: "Maximo",
   delegatecharge: "Delegar Cargo",
    logout: "Cerrar Sesión ",
     input:" aportar ",
   copy:" Copiar ",
    complete:" Completar ",
    ahora:" ",
    conectarMetamask:" Conectar Cartera ",

    whitePaper:" Papel Blanco ",
    nps:" Sistema de propuestas Nutbox ",
   contract : "Direcciòn de Contrato",
    social: "Informaciòn Social",
    docs: "Documentos",
    discord: "Discord",
    blog: "Blog",
    contractAddr: "Dirección de Contrato",
    search: "Buscar",
    language: "Menú Pri ncipal ",
    idioma:" Languaje ",
    zh:" 中文 ",
    en:" Ingles ",
    kr:" 한국어 ",
    my:" Malasia ",
    tr:" Turco ",
    es:" Español ",
    jp:" 日本語",
    goToHome:" Volver a la página principal ",
    home: 'Inicio',
    dappStore: 'Tienda de DApps'
  },
  tutorial: {
    createProcess:" Creación de Proceso ",
    step0:" Paso0: Registrat Activo ",
    step1:" Paso2: Escoger un activo y setear su distribución ",
    step2:" Paso2: Completar la información de la comunidad ",
    step3:" Paso3: Creación del grupo de participación ",
    demoVideo:" Video de ejemplo ",
    getStart:" Comenzar ",
    watchVideo : "Ver video de ejemplo",
    videoTip1:
      "En este video, lo guiaremos a través del proceso de generación del grupo de participación, de principio a fin. Tarda menos de {min} minutos. ",
    VideoTip2:" Haga clic en el botón de reproducción para iniciar el video ",
  },
  wallet: {
   wallet:" Cartera ",
    connectSteem:" Conectar Cartera de Steem ",
    connectHive:" Conectar Cartera de HIVE ",
    install:" Instalar ",
    transfer:" Transferir ",
    asset:" Activo ",
    nomination:" Voto de delegadores ",
    contribution:" Crowdloan ",
   balance:" Balance ",
   bond:" Vinculación ",
    unBond : "Desvincular",
   redeem: "Canjear",
    bindAmount: "Monto vinculante",
    inputAmount: "Cantidad de entrada",
    receiveAddress: "Dirección del receptor",
    transferAmount: "Monto de la transferencia",
    unbindAmount: "Importe de desvinculación" ,
  },
  cs: {
    crowdstaking: "Crowdstaking",
    deposit: "Depositar",
    steemDelegate: "Steem Delegate",
    hiveDelegate: "Hive Delegate",
    delegate: "Delegate",
    crowdloan: "Crowdloan",
   nomination: "Nominador",
    community: "Comunidad",
    nominate: "Voto",
   nominated: "Votado",
    bond: "Vincular",
    bondedAmount: "Vnculado",
    available: "Capital Disponible",
    bondAndNominate: "Vicnulas y votar" ,
    cancel: "Cancelar",
    bondInfo1:
      "Conserve una cierta cantidad de criptomonedas para obtener más tarifas de transacción",
    bondInfo2: "El monto mínimo de la fianza es {min} {símbolo}.",
    bondInfo3: "El proceso de desvinculación tardará {días} día .. ",
    confirm:" Confirmar ",
    cancelValidorsInfo:
      " Cancele al menos {} nodos que votó antes de votar otros nodos ",
  },
  validator: {
   validator:" Validador ",
    commission:" Comisiòn ",
    otherStake: "Otros",
    ownStake: "Mis staking",
    waiting: "Esperando",
  },
 date: {
    year: "Año",
    month: "Mes",
   day: "Día",
   hour: "Hora",
    min : "Minuto",
    sec: "Segundo",
  },
  cl: {
    crowdloan: "Crowdloan",
    community: "Comunidad",
   contribute: "Contribuir",
   withdraw: "Retirar",
    youWillWithdraw: "¿Desea retirar?",
    confirm : "Confirmar y aceptar",
    amount: "Monto",
    inputAmount: "Monto de Staking",
     nominator: "Nominador",
    inputNominator: "Tu Dirección de Nominador",
    optional: "(Opcional)",
    completed: "Completo",
    leasePeriod: "Periodo de Alquiler",
    countDown: "cuenta regresiva ",
    rewards:" Recompensas ",
    auctionPlan:" Recompensa de Crowdloan ",
   auctionInfo:" Detalle del Crowdloan ",
    joinAuction:" Únete al Crowdloan ",
    raised:" Elevado ",
     fund:" Encontrado ",
    progress:" Progreso " ,
    contributors: "Contribuyentes",
    contributed: "Mi Contribución",
    myBalance: "Balance del {token}",
    Active: "Activo",
    Winner: "Ganar",
    Retired: "Retirar",
    Completed: "Completar",
    chain: "Chain",
    trieIndex: "TrieIndex", 
    contributeAmount: "Monto a contribuir",
    contributeTime: "Tempo de Contribución",
  },
 transaction: {
    broadcasting: "La transacción ha sido difundida.",
    contributeOk:" ¡Contribución exitosa! ",
    withdrawOk:" Retiro Existoso! ",
    nominateOk:" Nominado Existoso! ",
    transactionOk:" Transacción Exitosa! ",
    depositOk:" Deposito Exitoso ", 
   withdrawOk :" Disminuir el éxito del depósito! ",
  },
  stake: {
    stake:" Minería de participación ",
    creaseDelegation:" Delegar ", 
   increaseDelegation :" Eliminar Delegación ",
    getSp:" Obtener SP ",
    get:" Obte ner ",
   stake:" Depositar ",
    unStake:" Eliminar Depósito ",
    stakedr:" apostar ",
  },
  tip: {
    aprobarSuccess:" Aprobación Exitosa ",
    loading:" Cargando, Por favor espere ",
    connectingPolkadot:" Conectando a la red Polkadot ",
    noAuction:" No hay subasta en curso ",
    noProject:" No hay proyecto en curso ",
    noNominations:" No hay Nodos de Nominadores ",
    noContribuitons:" No hay Contribuciones Registradas ",
    noCommunities:" No hay comuninades registradas ",
    noCrowdloan:" No hay Crwodloan ",
    noStakingProject:" No hay validadores ",
    tokenSafeTip:
      " Su activo se bloqueará directamente en la plataforma de préstamo colectivo oficial {símbolo}, no en ninguna cuenta de Nutobx. ",
    belowMinContribution:
      " La entrada es menor que la contribución mínima permitida de {min} ",
    belowMinBond:" La entrada es menor que el mínimo de 0,1 ",
    wrongNominatorAddress:" Dirección de Nominador Invalida ",
    wrongTransferAddress:" Dirección de Transferencia Invalida ",
    inputAddressType: "Por favor introduce la {relaychain} Dirección",
    insufficientBalance: "Balance Insuficiente",
    outOfCap: "Sin tapa",
    tips: "Tips",
    error: "Error！",
    success: "¡Exito!",
    warning: "Peligro！",
    noNeedWithdraw: "No necesita retirar",
    comingSoon : "Pronto",
   clipboard: "Portapapeles",
    copyAddress: "Dirección Copiada: {dirección}",
    signing: "Unirse",
    picUploadFail: "Las imágenes no se cargan. Vuelve a intentarlo. ",
    UploadLogo:" Cargue el logotipo ",
    deloyTokenFail:" El token no se pudo implementar. Vuelve a intentarlo. ",
    NotContractAddress:" Proporcione la dirección del contrato del token de implementación ",
    registryAssetFail:" Error de activo de registro ",
    registryAssetSuccess:" Éxito de los activos del registro ",
    wrongStartBlockNum:
      " Start BlockNum debe ser mayor que el BlockNum actual ",
    wrongStopBlockNum:" Stop BlcokNum debe ser mayor que Start BlockNum ",
    youHaveCreatedCommunity:" Ha creado una comunidad ",
    pleaseFillData:" Seleccione implementar políticas de distribución y activos ",
    deployFactoryFail:" Error de fábrica al implementar, inténtelo de nuevo ",
    deployFactorySuccess:" Factory deloy Success, por favor espere ",
    deploySuccess:" Éxito de Deloy ",
    storeTokenAddress:
      " Confirme la información y almacene la dirección del token de inmediato ",
    needName:" Ingrese el nombre de la comunidad ",
    needWebsit: "Ingrese el sitio web de la comunidad",
    needDescription: "Ingrese la descripción de la comunidad",
    needIcon: "Sube el logotipo de la comunidad",
    needPoster: "Sube el póster de la comunidad ",
    completeCommunityInfoSuccess:" Actualización de la información de la comunidad con éxito ",
    wrongSubstrateAddress:" Ingrese la dirección {type} correcta ",
    ratioError:" La proporción total de Staking Pool es 100 ",
    wrongApy:" Ingrese APR correcto ",
    wrongRewardNum: "Ingrese las recompensas correctas",
    createBlogSuccess: "¡Crear blog exitoso!",
    communityNameLimit: "El nombre de la comunidad no puede exceder {count} palabras",
    needRightUrl: "Por favor ingrese la URL correcta.",
    selectCowdloan: " Seleccione un proyecto de préstamo colectivo. ",
    InputRightBlogTag:
      " Ingrese una etiqueta de comunidad steem correcta. por ejemplo: hive-12345. ",
    removeSuccess:" Cosecha Exitosa! ",
    deploying:" La implementación, tal vez le cueste algo de tiempo, espere un momento. ",
    stopPoolOk:" Pool has been stop ",
    removePoolOk:" La piscina ha sido eliminada ",
    tryWithdrawOk:" Canjear el éxito. ",
    stopPoolTips:" Es necesario establecer la proporción del grupo en 0 antes de detener el grupo. ",
    completeCommunityProposalConfigSuccess:" Éxito en la configuración de la propuesta de la comunidad ",
    completeProposalSuccess: "Éxito de la propuesta",
    completeVoteSuccess: "Voto Existoso",
    needGameName: "Ingrese el nombre del juego",
    gameNameLimit: "El nombre del juego no puede exceder {count} palabras",
    needGameType: "Elige el tipo de juego ",
    completeGameSuccess:" Juego Exitoso ",
    deployTokenTip: 'Perhatian: ini adalah jumlah mint pre-distribusi ke akaun anda sendiri, bukan keseluruhan bekalan.Tolong berhati-hati.',
    attention: 'Perhatian!',
    stopPoolAttention: 'Setelah kau hentikan kolam renang, ia tak boleh dibuka lagi.Sila gunakan fungsi ini dengan berhati-hati!'
  },
  nps: {
    nps:" Governanza ",
    npsTemp:
      " Muestre la gobernanza del maní, por ejemplo. La gobernanza de la nuez está en desarrollo. "
,all:" Todo ",
    pass:" Paso ",
    pending:" Pendiente ",
    unpass:" Rechazado ",
    rolling:" Votar ",
    mine:" Mi Publicación ",
   title: "Título",
    titleInputTip: "Por favor escriba un título",
    startAndEndTime: "Comienzo y final",
    startTime: "Comienza el tiempo",
    endTime: "Tiempo final",
   details: "Detalles",
    detailsInputTip: "Por favor introduzca los . detalles de la propuesta ",
    proposalConfigLabel: "Configurar propuesta",
    voteProposal: "votación la propuesta",
    createProposal: "Crear propuesta",
    noProposals: "No hay propuestas",
    proposalTitle: "título",
    proposalTitleInput: "Por favor, introduzca el título propuesta",
    proposalStart: "Tiempo de inicio",
    proposalEnd: "Tiempo final",
    proposalFirst_Block: "Iniciar Bloque",
   proposalEnd_Block: "Parar bloque",
    proposalBody: "Detalles",
    proposalBodyPreview: "Vista previa",
    proposalBodyInput:" Por favor introducir los detalles " ,
    proposalNetwork: "Red",
    proposalNetworkInput: "Selecciona la Red Red",
    proposalSymbol: "Símbolo",
    proposalSymbolInput: "Símbolo",
    proposalStrategies: "Estrategias",
    proposalPassThreshold: "Umbral",
    proposalThresholdInput: "Por favor introduce el Umbral",
   proposalPassThreshold: "Pasa el Umbral",
    proposalPassThresholdInput: "Por favor introduce el Umbral",
    proposalValidation: "Validaciòn ",
    proposalValidationInput:" Por favor introduce la",
   proposalAgreeBtn :" De acuerdo ",
    proposalDisagreeBtn:" Desacuerdo ",
    propsalSureVote:" Está seguro ",
    propsalVoteRight:" Haz votado correctamente ",
    propsalVoteRemind:" Estás seguro {0}? " ,
    propsalVoteStatusWaitStart: "Esperando",
    propsalVoteStatusDoing: "Votando",
    propsalVoteStatusEnd: "Fin",
    proposalInfo: "Información de la propuesta",
   proposalVoteNum: "Número de votos",
   proposalVoteResult: "Resultados de la votación",
  proposalStatus: "Resultados de la votación",
    editProposalConfigBtn:" PropuestaConfirmada ",
    editStrategy:" Editar Estrategia ",
    validationWarning: {
      basic: {
        minScore:
          " Debe tener un mínimo de {0} {1} para enviar una propuesta. ",
      },
      customValidation:
        " Debe aprobar la validación de la propuesta para poder enviar una propuesta .. ",
    },
    remarkInput:" Por favor añade una intro ",
    remark:" Intro ",
    markdownTip:" Uasaras el formato del Markdown. ",
    downloadReport:" Descargando Reporte. ",
  },
  error: {
    error:"Error",
    depositFail:"Depósito",
    withdrawFail: "retiro Fallido",
    changeDepsitFail:"changeDepsitFail:" ,
    delegateFail:" Delegaciòn Fallida ",
    changeDelegateFail:" Cambio en la delegación Fallida ",
    ApproveFail:" Aprobaciòn Fallida ",
    accountChanged:
      " No coinciden las direcciones Steem y tron ， Confirme si la cuenta de billetera está cambiada ",
    delegateerror:" Error de delegación！ ",
    tryrefreshpage : "Error, actualice la página y vuelva a intentarlo！",
    steemloginerr: "¡Falló el inicio de sesión de Steem!",
    Needkeychainandtronlink: "Necesita instalar Steem Keychain y TronLink！",
    needkeychain: "Instalar Steem Keychain",
    needtronlink: "Instalar TronLink",
   insufficientEnerge: "No hay suficiente energía",
    transferFail: "Transferencia fallida",
    unlockKeychain: "Desbloquea el llavero Steem",
    unlockWallet: "Desbloquea tronlink",
    steemLoginFail: "Steem falló al iniciar sesión",
    hiveLoginFail: "Hive falló al iniciar sesión",
    illegalTronAddress: "Dirección de Tron invalida ",
    inputError:" Ingrese un valor incorrecto ",
    inputOverflow:" Input overflow ",
    inputOutOfRange:
      " Ingrese un valor entre {lowerPnutAmount} - {upperPnutAmount} ",
    inputLinkIllegal:" Ingrese el enlace correcto ",
    notEnoughFee:" Tarifa insuficiente ",
    insufficentPnut : "Insufficient Pnut",
    hasVoted: "Su publicación ha sido votada por nutbox.mine", 
     pleaseRetry: "Por favor, intente nuevamente",
    signatureFailed: "firma, actualice la página yintentarlo",
    serveError: "Error de servidor",
    networkError : "Error de red",
    noStakingFactory: "Aún no ha creado una comunidad",
    blockChainError: "Error de invocación del contrato",
    cancelSigning: "Cancelación de firma",
    unknow: "Error desconocido",
    notConnectWallet: "Wallet no",
    unlockWallet: "Desbloquee Wallet",
    wrongChainId: "Wron g ChainID ",
    wrongETHAddress:" Dirección ETH incorrecta ",
    wrongInputDevRatio:" Ingrese un número entre 0-100 ",
    notTokenContract:" La dirección que ingresó no es una dirección de contrato ERC20. ",
    transactionFail:" ¡Transacción fallida! ",
    assetHasRegisterd: "Alguien ha registrado el activo",
    steemAccountEmpty: "Falta la cuenta de Steem",
    steemAccountActiveKeyEmpty: "Falta la cuenta de",
    hiveAccountEmpty: "Falta la cuenta de Hive",
    hiveAccountActiveKeyEmpty: "Falta la cuenta de Hive o la clave activa",
    tokenDeploying:
      "Hay un token en la implementación. Espere o actualice la página para intentarlo.",
  },
  community: {
    community: "Comunidad",
    percentage: "Porcentaje",
    communityInfo: "Información",
    social: "Social",
    pool: "Grupo" ,
    edit: "Editar",
    create: "Completo",
    communityList: "Community List",
    communityTokenList: "C-Token List",
    communityName: "Nombre",
    communityLink: "Enlace oficial",
    communityDesc: "Descripción",
    communityLogo: "Logotipo",
    communityThemeColor: "Color del tema",
    communityPoster: "Poster",
    communityBalance : "Balance de la comunidad",
    communityAsset: "Community Asset",
    commit: "Commit",
    inputName: "Ingrese el nombre de la comunidad",
    inputLink: "Ingrese el vínculo del sitio web de lainputDesc: ",
    inputApy: " Ingrese Apr ",
    uploadLogo:" Cargar logotipo ",
    picTip:
      " El tamaño recomendado es {tamaño}, menor que 2M, admite formatos jpg, png, jpeg ",
    uploadPoster:" Cargar póster ",
    poster:" Póster ",
    noCommunity : "Aún no tienes una comunidad",
    gotoCreate: "Ve a crear ahora",
    editTip:
      "La carga de información de la comunidad requiere el uso de la firma de tu billetera, esta operación no te costará nada, puedes estar seguro de usar。",
    sign: "Regístrate y sube",
    createCommunity: "Crea tu economía deexclusiva",
    step1: "Paso 1",
    step2: "Paso 2",
    yourCTokenId: "Paso 1 ： Elige un activo",
    settingTokenDistribution: "Paso 2 ： Configura tu activo distribution ",
    tokenEra:" Distribución total por política actual ",
    currentBlock:" Altura del bloque actual ",
    startBlock:" Iniciar bloque ",
    inputStopBlock:" Ingrese la altura del bloque de parada ",
    inputBlockReward:" Ingrese recompensas por bloque ",
    stopBlock:" Detenga el bloque ",
    recompensaAumentar:" Cantidad de recompensa ",
    comfirmAdd:" Confirmar agregar ",
    personal:" Mi activo personal registrado ",
    oficial:"oficialmente asset ",
    addPoolSuccess:" Staking Pool agregado con éxito ",
    updatePoolSuccess:" Staking Pool actualizado correctamente ",
    dashboard:" Dashboard ",
     setting:" Configuración ",
    updatePools:" Ajustar datos dePool ",
    addPool:" Agregar grupo de Staking ",
stopPool: "Detener grupo",
 withdrawPool: "Limpiar grupo",
    removePool: "Eliminar grupo",
    Active: "Activo",
    Stopped: "Detenido",
    CanRemove: "Detenido",
    Removed: "Eliminado",
    noPools: "Sin replanteo Pools yet ",
    userCount:" Number of users ",
    totalDeposit:" Total",
    totalDepositDollor:" TVL ",
    hasMined:" Token has Mined ",
    more:" More ",
    join:" Join ",
    createYourCommunity:" Crea tu Community ",
    communityDashboard:" Administra tu comunidad ",
    charge:" Charge ",
    communityCharge:" Community Charge ",
    confirmCharge:" Confirm Charge ",
    chargeSuccess:" Carga exitosa ",
    devAddress:" Community fund address ",
    devRatio:" Community commision ratio ",
    inputDevRatio:" Ingrese un número entre 0-100 ",
    inputDevAddress:" Input community devAddress ",
    publishPool:" Publish Staking Pool ", 
    publicado:" No publicado ",
    publishBlog:" Publicar blog ",
    communityBlog:" Blog de la comunidad ",
    socialMedial:" Social Medial ",
    blogTag:" Etiqueta de blog ",
    createBlog:" Crear blog ",
    createBlogMemo:
      " La etiqueta de tu blog y la clave privada se enumeran a continuación. Consérvelos con cuidado. No proporcionamos servicios de custodia. El proceso de creación de una cuenta de blog consumirá sus 3 STEEM como tarifa de servicio. ",
    BlogMainPassword:" Contraseña ",
    publishBlogSuccess:" ¡Publicar éxitos de blog! ",
    DistributionLimit:" La distribución máxima es de 6 ",
    bindBlog:" Vincular el blog de tu comunidad ",
    bindBlogMemo: "Ingrese su etiqueta de comunidad Steem para bind.eg:hive-12345",
    inputBlogTag: "Ingrese su etiqueta de comunidad Steem",
    updateSocialSuccess: "¡Actualice la información social con éxito!",
    createNeedStake: "Crear un nuevo grupo necesita su participación: ",
    notYourCommunity:" Información de comunidad incorrecta ",
  },
  asset: {
    asset:" Activo ",
    red:" Red ",
     native:" Activo nativo ",
    foreign:" Activo extranjero ",
    registerCtokenTitle:" Registra el activo de tu comunidad ",
    registerTitle: "Registre su activo exclusivo",
    yourSteemAccount: "Su cuenta de/ Hive",
    SteemassetName: "Nombre esté activo",
    assetProperties: "Propiedades del activo",
    register: "Registro",
    homeLocation: "Ubicación del token de inicio",
    tokenName: "Token Name",
    tokenSymbol: "Token Symblo",
    distributionAmount: "Importe de distribución",
    inputHomeLocation: "Ingrese la dirección ERC20",
    inputTokenName: "Ingrese el nombre del token",
    inputTokenSymbol: "Ingrese el símbolo del token",
    inputDistributionAmount: "Ingrese la cantidad de distribución",
    notDeploy: "¿Aún no hay token?",
    noTokens: "Didn ' t crear token de comunidad ",
    deployOne:" Despoliar uno ",
   deploy:" Implementar ",
    deployYourToken:" Implementar un token ",
    notRegister:" Aún no hay ningún activo registrado？ ",
    registerOne:" Registrar uno ",
    registerCAsset:" Registrar un activo de la comunidad ",
    inputAccount:" Ingrese su cuenta ",
    inputAssetName:" Ingrese el nombre del activo ",
    parachainId:" ParahainID ",
    inputParachainId:" Ingrese ParachainID ",
    trieIndex:" Trie Index ",
    inputTrieIndex:" Ingrese Trie Index ",
    communityAddress: "Dirección de la comunidad （Dirección de Polkadot）",
    inputCommunityAddress: "Introduzca la dirección de polkdot de la comunidad",
    EndBlock: "Fecha válida del activo",
    inputEndingBlock:
      "Introduzca la altura del bloque final cuando el activo no sea válido",
    registerSteemTab: "Registrar STEEM / Activo vinculante HIVE ",
    registerCrowdloanTab:" Regis activo de enlace ter Polkadot / Kusma Crwodloan ",
    registerValidatorTab:" Registrar activo de enlace de nodo de validación de olkadot / Kuama ",
    validatorNodeAddress:" Dirección del nodo del validador ",
    inputValidatorNode:" Por favor, ingrese la dirección del nodo del validador ",
    delegation:" Delegation ",
    crowdloan:" Crowdloan ",
    validador:" Validator node ",
    addPool:" Agregar grupo de participación ",
    updatePool:" Ajustar grupo de participación ",
    poolRatios:" Proporción de participación en los beneficios del grupo de participación ",
    poolInfo:" Información de la agrupación de participación ",
    stakingAsset:" Activo de participación ",
    selectStakingAsset: "Por favor, seleccione el activo de replanteo",
    isHomeAsset: "Activo local",
    isForeignAsset: "Activo extranjero",
    poolName: "Nombre del pool de",
inputPoolName: "Ingrese el nombre del pool de replanteo",
   cap: "Mkt Cap",
    price: "Price",
    totalSupply: "Supply",
  },
  game: {
    game: 'Juego',
    recommend: 'Recomendar',
    popular: 'Popular',
    others: 'Otros',
    noGames: 'Sin juegos',
    create: "Crear ",
    edit:" Editar ",
    gameName:" Nombre del juego ",
    gameLink:" Enlace de página ",
    gameDesc:" Descripción ",
    gameCategory:" Categoría ",
    gameLogo:" Logo ",
    gamePoster: "Póster",
    inputGameName: "Ingresa el nombre del juego",
    inputGameLink: "Ingresa el enlace de la página oficial del juego",
    inputGameDesc: "Describe tu juego",
    noGame: "El juego no existet",
  },
};


