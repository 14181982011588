<template>
  <div id="app">
    <div class="page-layout">
      <div class="page-side">
        <div class="text-center">
          <div @click="goHome()" class="hover shadow-hover rounded-circle">
            <img
              style="width: 2.8rem; height: 2.8rem"
              src="~@/static/images/logo_small.png"
              alt=""
            />
          </div>
          <div class="divider-line mx-auto my-4"></div>
          <!-- function list -->
          <div class="pt-3 communities-bar">
            <!-- <div class="community-logo-box">
              <img
                id="treasury"
                @click="gotoTreasury"
                class="rounded-circle w-100 hover"
                :class="path === '/treasury' ? 'active' : ''"
                src="~@/static/images/icon_treasury.png"
                alt=""
              />
              <b-popover
                target="treasury"
                triggers="hover focus"
                placement="top"
              >
                Treasury
              </b-popover>
            </div> -->
            <div class="community-logo-box">
              <img
                id="reward"
                @click="gotoReward"
                class="rounded-circle w-100 hover"
                :class="path === '/reward' ? 'active' : ''"
                src="~@/static/images/reward-to-staker.png"
                alt=""
              />
              <b-popover
                target="reward"
                triggers="hover focus"
                placement="top"
              >
                Atom Distribute
              </b-popover>
            </div>
            <div class="community-logo-box">
              <img
                id="peanut_nft"
                @click="gotoPeanutNFT"
                class="rounded-circle w-100 hover"
                :class="path === '/peanut_nft' ? 'active' : ''"
                src="~@/static/images/pnut.png"
                alt=""
              />
              <b-popover
                target="peanut_nft"
                triggers="hover focus"
                placement="top"
              >
                Peanut NFT
              </b-popover>
            </div>
          </div>
        </div>
      </div>
      <!-- right part -->
      <div class="page-container">
        <div
          class="page-header d-flex justify-content-between align-items-center"
        >
          <div class="page-title font-bold font28 line-height32">
            {{ pageTitle }}
          </div>
          <div class="address-box" @click="connect">
            <i class="wallet-icon"></i>
            <div class="font12">
              {{ metamaskConnected ? address : $t("commen.connectMetamask") }}
            </div>
          </div>
        </div>
        <div class="page-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOCALE_KEY } from '@/config'
import { mapState, mapActions, mapGetters } from 'vuex'
import { setupNetwork, chainChanged, lockStatusChanged, checkNetwork } from '@/utils/web3/web3'
import { accountChanged, getAccounts, updateAllUsersByPolling } from '@/utils/web3/account'
import { subBlockNum } from '@/utils/web3/block'
import { getMyCommunityInfo, updateAllCommunitiesFromBackend, getOperationFee } from '@/utils/web3/community'
import { updateAllTokensFromBackend } from '@/utils/web3/asset'
import { formatUserAddress } from '@/utils/helper'
import { getMyJoinedCommunity } from '@/utils/graphql/user'
import showToastMixin from './mixins/copyToast'
import { ethers } from 'ethers'
import { getCommon } from '@/apis/api'
import { connectWallet as connectKeplr } from '@/utils/cosmos/cosmos'
import { getAllCommunities } from './utils/web3/community'

export default {
  computed: {
    ...mapState(["lang", "prices", "metamaskConnected"]),
    ...mapState("web3", [
      "allCommunities",
      "stakingFactoryId",
      "loadingCommunity",
      "account",
    ]),
    ...mapState("community", ["loadingMyCommunityInfo", "communityInfo"]),
    ...mapState("currentCommunity", ["communityId"]),
    ...mapState("user", ["userGraphInfo"]),
    ...mapGetters("community", ["getCommunityInfoById"]),
    ...mapGetters("user", ["getUserByAddress"]),
    address() {
      if (ethers.utils.isAddress(this.account)) {
        return formatUserAddress(this.account, false);
      }
    },
    pageTitle() {
      const url = this.$route.path;
      if (url === "/") return "Operation System";
      else if (url.indexOf("/community") !== -1) return "Community";
      else if (url.indexOf("/manage-community") !== -1)
        return "Community Dashboard";
      else if (url.indexOf("/profile") !== -1) return "Profile";
      else if (url.indexOf("/create") !== -1) return "Create Community";
    },
    path() {
      return this.$route.path;
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      langActive: false,
      langOptions: ["en", "kr", "es", "my"],
    };
  },
  mixins: [showToastMixin],
  methods: {
    ...mapActions("steem", ["setVestsToSteem"]),
    ...mapActions("hive", ["setVestsToHive"]),
    setLanguage(lang) {
      this.langActive = false;
      localStorage.setItem(LOCALE_KEY, lang);
      this.$store.commit("saveLang", lang);
      this.$i18n.locale = lang;
    },
    gotoTreasury() {
      this.$router.push('/treasury')
    },
    goHome() {
      this.$router.push("/");
    },
    gotoReward() {
      this.$router.push('/reward')
    },
    gotoPeanutNFT() {
      this.$router.push('/peanut_nft')
    },
    connect() {
      if (this.metamaskConnected) {
        this.onCopy(
          this.$t("tip.copyAddress", {
            address: formatUserAddress(this.address),
          }),
          { title: this.$t("tip.clipboard") }
        );
        return;
      }
      setupNetwork();
    },
  },
  async mounted() {
    this.setLanguage(localStorage.getItem(LOCALE_KEY) || "en");
    connectKeplr()
    getAllCommunities()
    getCommon().then((res) => {
      if (!res) return;
      this.$store.commit("saveTvl", res.tvl);
      this.$store.commit("savePrices", res.prices);
      this.$store.commit("steem/saveVestsToSteem", res.vestsToSteem);
      this.$store.commit("hive/saveVestsToHive", res.vestsToHive);
      this.$store.commit("np/saveCommonData", res.npDatas);
    });
  },
  async created() {
    try {
      // setupNetwork()
      await checkNetwork();
      chainChanged(() => {
        this.$router.go(0);
      });
      accountChanged(() => {
        this.$router.go(0);
      });
      lockStatusChanged(() => {
        this.$router.go(0);
      });
      subBlockNum();
    } catch (e) {
      console.log("Initial network fail", e);
    }
  },
}
</script>

<style lang="scss">
:root {
  --yellow-background: #f5ecd8;
  --primary-custom: #fd9800;
  --primary-custom-rgb: 255, 219, 27;
  --primary-hover: #ffeb75;
  --primary-text: white;
  --primary-btn-text-color: #ffffff;
  --secondary-text: #717376;
  --disable: #cdcecf;
  --dividers: #242526;
  --background: #1d1e1f;
  --error: #ff5040;
  --success: #50bf00;
  --link: #408fff;
  --warning: #ff9500;
  --backgroud-state: #b37012;
  --primary: #f8b62a;
  --card-broder: #37383c;
  --card-bg-primary: #141414;
  --nav-tab-bg: #2c2d2e;
  --nav-tab-bg-active: #141414;
  --input-border: #2c2d2e;
  --input-bg: #2c2d2e;
  --block-bg: #1c1d1e;
  --modal-bg: #1d1e1f;
  --text-47: #474849;
  --text-74: #747576;
  --text-bd: #bdbfc2;
  --text-9f: #9fa0a0;
  --sub-primary: #ffdb1b;
}
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
@import "static/css/responsive";
@import "static/css/font";
@import "static/css/icon";
@import "static/css/common.scss";
@import "static/css/layout";
@import "static/css/modal";
@import "static/css/dropdown";
html,
body {
  height: 100%;
  margin: 0;
  background-color: var(--background);
}
::-webkit-scrollbar {
  display: none;
}
#app {
  font-family: PingFang SC, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-text);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
}
.container {
  max-width: 1360px;
  padding-left: 40px;
  padding-right: 40px;
}
.communities-bar {
  .community-logo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    border-radius: 48px;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    &:hover {
      box-shadow: 0 0 8px 2px var(--primary-custom);
    }
  }
  .active {
    border: 2px solid var(--primary-custom);
  }
  .community-logo-box img {
    max-width: 40px;
    max-height: 40px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
}
.address-box {
  height: 2rem;
  border: 1px solid var(--card-broder);
  border-radius: 2rem;
  background-color: rgba(255, 219, 38, 0.05);
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem 0.2rem 0.2rem;
  cursor: pointer;
  .wallet-icon {
    border: 1px solid var(--card-broder);
    border-radius: 50%;
    height: 1.6rem;
    width: 1.6rem;
    padding: 0.2rem;
    margin-right: 0.3rem;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      @include icon(12px, 12px);
      background-image: url("~@/static/images/wallet.svg");
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &:hover {
    border-color: var(--primary-custom);
    color: var(--primary-custom);
    .wallet-icon {
      border-color: var(--primary-custom);
      &::after {
        background-image: url("~@/static/images/wallet-hover.svg");
      }
    }
  }
}
.community-logo {
  width: 2rem;
  height: 2rem;
}
.dropdown-menu-card {
  @include card(1.2rem 0, #2c2d2e);
  border: 1px solid #747576;
  i {
    @include icon(1.6rem, 1.6rem);
    margin-right: 0.4rem;
  }
  span {
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    //user-select: none;
  }
}
.github-icon {
  background-image: url("~@/static/images/h-github.svg");
}
.docs-icon {
  background-image: url("~@/static/images/h-docs.svg");
}
.discard-icon {
  background-image: url("~@/static/images/h-discord.svg");
}
.twitter-icon {
  background-image: url("~@/static/images/h-twitter.svg");
}
.telegram-icon {
  background-image: url("~@/static/images/h-telegram.svg");
}
.medium-icon {
  background-image: url("~@/static/images/h-mdeium.svg");
}
.back-icon {
  background-image: url("~@/static/images/back.svg");
}
</style>
