import { getAllStakeAtomToNutboxOperations } from '@/utils/graphql/operation'
import { aggregate } from '@makerdao/multicall'
import { addressAccToAccBech32 } from '@/utils/cosmos/cosmos'
import { Multi_Config } from '@/config'

export const getAtomStakeList = async () => {
    return new Promise(async (resolve, reject) => {
        try{    
            let ops = await getAllStakeAtomToNutboxOperations()
            let stakeInfo = []
            let addedFlag = {}
            for (let op of ops) {
                if (!addedFlag[op.user]) {
                    addedFlag[op.user] = {}
                }

                if (!addedFlag[op.user][op.pool.id]) {
                    addedFlag[op.user][op.pool.id] = true;
                    stakeInfo.push(op)
                }
            }
            let finalStakeList = await aggregate(stakeInfo.map(s => ({
                target: s.pool.id,
                call: [
                    'getUserDepositInfo(address)(bool,uint256,address)',
                    s.user
                ],
                returns:[
                    [s.user + '-' + s.pool.id + '-hasDeposited'],
                    [s.user + '-' + s.pool.id + '-amount', val => val.toString() / 1e6],
                    [s.user + '-' + s.pool.id + '-bindAccount', addressAccToAccBech32],
                ]
            })), Multi_Config)
            const transformed = finalStakeList.results.transformed
            let result = []
            for (let info in transformed) {
                const [user, pool, type] = info.split('-')
                if (type === 'amount' && transformed[info] > 0.00001) {
                    result.push({
                        user,
                        pool,
                        amount: transformed[info],
                        bindAccount: transformed[user + '-' + pool + '-bindAccount']
                    })
                }
            }
            resolve(result)
        }catch (e) {
            reject(e)
        }
    })
}