import {
  getContract
} from './contract'
import store from '@/store'
import {
  errCode,
  Multi_Config,
  treasuryTokens
} from '@/config'
import {
  waitForTx
} from './ethers'
import {
  aggregate
} from '@makerdao/multicall'
import {
  getMyCommunityContract
} from './community'
import {
  ethers
} from 'ethers'
import {
  rollingFunction
} from '@/utils/helper'
import {
  getISOPools
} from '@/utils/graphql/operation'
import { getPoolType } from './pool' 
import { getAccounts } from '@/utils/web3/account'

export const getMyTreasury = async () => {
  return new Promise(async (resolve, reject) => {
    let communityId = null
    try {
      communityId = await getMyCommunityContract()
      if (!communityId) {
        reject(errCode.NO_STAKING_FACTORY)
        return;
      }
    } catch (e) {
      reject(e)
      return
    }
   resolve(await getTreasury(communityId))
  })
}

export const getTreasury = async (communityId) => {
    try {
        const contract = await getContract("TreasuryFactory");
        const treasury = await contract.treasuryOfCommunity(communityId)
        return treasury
    }catch (e) {
        return
    }
}

export const getTreasuryTokens = async () => {
    return new Promise(async (resolve, reject) => {
        try{
            let treasuryTokens = store.state.web3.treasuryTokens
            if (treasuryTokens) {
                resolve(treasuryTokens)
                return;
            }
            const contract = await getContract("TreasuryFactory");
            treasuryTokens = await contract.getRewardList();
            store.commit('web3/saveTreasuryTokens', treasuryTokens)
            resolve(treasuryTokens)
        } catch (e) {
            return
        }
    })
}

export const getTreasuryBalance = async (treasury) => {
    return new Promise(async (resolve, reject) => {
        try{
            let treasuryTokens = await getTreasuryTokens()
            if (treasuryTokens) {
                const balances = await aggregate(treasuryTokens.map(t => ({
                    target: t,
                    call:[
                        'balanceOf(address)(uint256)',
                        treasury
                    ],
                    returns: [
                        [t + '-balance']
                    ]
                })).concat(treasuryTokens.map(t => ({
                  target: t,
                  call: [
                      'symbol()(string)'
                  ],
                  returns: [
                      [t + '-symbol']
                  ]
              }))), Multi_Config)
              resolve(balances.results.transformed);
            }else{
                resolve({})
            }
        } catch (e) {
            reject(e)
        }
    })
}

export const createTreasury = async () => {
  return new Promise(async (resolve, reject) => {
    let communityId = null
    try {
      communityId = await getMyCommunityContract()
      if (!communityId) {
        reject(errCode.NO_STAKING_FACTORY)
        return;
      }
    } catch (e) {
      reject(e)
      return
    }
    let contract;
    try {
      contract = await getContract("TreasuryFactory", null, false);
      contract.on('NewTreasuryCreated', (community, treasury) => {
        if (community.toLowerCase() === communityId.toLowerCase()) {
          resolve(treasury)
        }
      })
      await contract.createTreasury(communityId)
    } catch (e) {
      reject(e)
    }
  })
}

export const redeem = async (treasury, amount) => {
  return new Promise(async (resolve, reject) => {
    try{
      const contract = await getContract('Treasury', treasury, false)
      const tx = await contract.redeem(ethers.utils.parseUnits(amount.toString(), 18))
      await waitForTx(tx.hash)
      resolve();
    }catch(e) {
      reject(e)
    }
  })
}


//   for operation
export const getISOPoolsData = async () => {
  try{
    let res = await getISOPools()
    // res = res.filter(r => r.pools.length > 0 && Number(r.treasury) !== 0)
    res = res.filter(r => r.pools.length > 0)
    return res;
  }catch(e) {

  }
}

export const getTreasuryTokenBalance = async () => {
  try{
    const account = await getAccounts();
    let balances = await aggregate(Object.values(treasuryTokens).map(t => ({
      target: t,
      call: [
        'balanceOf(address)(uint256)',
        account
      ],
      returns: [
        [t]
      ]
    })), Multi_Config)
    return balances.results.transformed;
  }catch (e) {
    console.log(1117, e);
  }
}

export const multiSend = async (token, addresses, amounts) => {
  try {
    const contract = await getContract('MultiSend', null, false)
    const tx = await contract.multi_send_token(token, addresses, amounts)
    await waitForTx(tx.hash)
  } catch (e) {
    if (e.code === 4001) {
      throw(errCode.USER_CANCEL_SIGNING);
    }
  }
}