<template>
    <div class="m-3 scroll-content">
        <div class="d-flex mb-3">
            <div class="flex-1 text-center">User address</div>
            <div class="flex-1 text-center">Pool address</div>
            <div class="flex-1 text-center">Cosmos address</div>
            <div class="flex-1 text-center">Delegated amount</div>
            <div class="flex-1 text-center">Distribute amount</div>
        </div>
        <div class="d-flex font14" v-for="(item, idx) in stakeInfo" :key="idx">
            <div class="flex-1 text-center" :id="'user' + idx">{{ item.user.substring(0, 12) + '...' }}</div>
            <div class="flex-1 text-center" :id="'pool' + idx">{{ item.pool.substring(0, 12) + '...' }}</div>
            <div class="flex-1 text-center" :id="'cosmos' + idx">{{ item.bindAccount.substring(0, 12) + '...' + item.bindAccount.slice(-8) }}</div>
            <div class="flex-1 text-center">{{ item.amount | amountForm }}</div>
            <div class="flex-1 text-center">{{ item.sendAmount | amountForm }}</div>
            <b-popover 
                :target="'user' + idx"
                triggers="hover focus"
                placement="top">
                {{ item.user }}
            </b-popover>
            <b-popover 
                :target="'pool' + idx"
                triggers="hover focus"
                placement="top">
                {{ item.pool }}
            </b-popover>
            <b-popover 
                :target="'cosmos' + idx"
                triggers="hover focus"
                placement="top">
                {{ item.bindAccount }}
            </b-popover>
        </div>
        <button class="primary-btn w-50 mt-5" @click="showSendModal = true">
            Send
        </button>

        <b-modal
            v-model="showSendModal"
            modal-class="custom-modal"
            size="l"
            centered
            hide-header
            hide-footer
            no-close-on-backdrop
            >
            <div class="custom-form font20 line-height28">
                <div class="modal-title font-bold mb-2">
                Distribute total {{ totalAtom | amountForm }} atom
                </div>
                <div class="d-flex mb-3">
                    <div class="flex-1 text-center">Cosmos address</div>
                    <div class="flex-1 text-center">Distribute amount</div>
                </div>
                <div class="d-flex font14" v-for="(item, idx) in distributeList" :key="idx">
                    <div class="flex-1 text-center">{{ item.bindAccount }}</div>
                    <div class="flex-1 text-center">{{ item.sendAmount | amountForm }}</div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" style="margin: 0 -1rem">
                <button class="dark-btn mx-3" @click="showSendModal = false" :disabled="sending">
                    <b-spinner small type="grow" v-show="sending">
                    </b-spinner>
                    Cancel
                </button>
                <button class="primary-btn" @click="distribute" :disabled="sending">
                    <b-spinner small type="grow" v-show="sending">
                    </b-spinner>
                    Distribute
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { getAtomStakeList } from '@/utils/web3/atomStake'
import { distributeRewards, getAccount } from '@/utils/cosmos/cosmos'
import { handleApiErrCode } from '../utils/helper'
import { sleep } from '@/utils/helper'

export default {
    name: "Reward",
    data() {
        return {
            stakeInfo: [],
            showSendModal: false,
            sending: false
        }
    },
    methods: {
        async distribute() {
            try{
                this.sending = true;
                await distributeRewards(this.distributeList)
                this.$bvToast.toast('Distribute Success', {
                    title: 'Success',
                    variant: 'success'
                })
                await sleep(5)
                this.showSendModal = false
            } catch (e) {
                handleApiErrCode(e, (title, info) => {
                    this.$bvToast.toast(title, info)
                });
            } finally {
                this.sending = false
            }
        }
    },
    computed: {
        distributeList() {
            return this.stakeInfo.filter(s => s.amount >= 200)
        },
        totalAtom() {
            return this.distributeList.reduce((a,b) => a + b.sendAmount, 0)
        }
    },
    mounted () {
        getAtomStakeList().then(res => {
            getAccount()
            this.stakeInfo = res.sort((a, b) => b.amount - a.amount).map(s => ({
                ...s,
                sendAmount: s.amount * 0.3 / 365
            }))
        })
    },
}
</script>

<style lang="scss" scoped>

</style>