module.exports = {
  commen: {
    steemAccoutPlaceHolder: "Steem Account",
    hiveAccountPlaceHolder: "Hive account",
    loginByKeychain: "Login by Keychain",
    loging: "Logging in...",
    crowdstaking: "Staking",
    optional: "(Optional)",
    connectMetamask: 'Connect Metamask',
    delegateFee: 'Delegate fee',

    apy: "APR",
    balance: "Balance",
    max: "Max",
    complete: "Complete",
    now: "Now",
    poster: "Poster",

    whitePaper: "White Paper",
    nps: "Nutbox Proposal System",
    contract: "Contract Address",
    social: "Social info",
    docs: "Docs",
    discord: "Discord",
    blog: "Blog",
    contractAddr: "Contract Address",
    dashboard: "Dashboard",
    auditReport: "Audit Report",
    language: "Language",
    zh: "中文",
    en: "English",
    kr: "한국어",
    my: "Malaysia",
    tr: "Türkçe",
    es: "Español",
    jp: "日本語",
    goToHome: "Back to homepage",
    home: 'Home',
  },
  operation: {
    withdraw: "Withdraw",
    harvest: "Harvest",
    approveContract: "Approve Contract",
    connectMetamask: "Connect Metamask",
    search: "Search",
    userlogin: "Login",
    uploadLogo: "Upload Logo",
    uploadPoster: "Upload Poster",
    sign: "Sign and upload",
    cancel: "Cancel",
    commit: "Commit",
    complete: "Complete",
    confirm: "Confirm",
    create: "Create",
    add: "Add",
    update: "Update",
    delegatecharge: "Delegate Charge",
    login: "Login",
    logout: "Log out",
    input: "Input",
    copy: "Copy",
    edit: "Edit",
    charge: 'Charge',
    redeem: "Redeem",
    enter: 'Enter',
    approve: 'Approve',
    grant: 'Grant',
    distribute: 'Distribute'
  },
  wallet: {
    wallet: "Wallet",
    connectSteem: "Connect Steem Wallet",
    connectHive: "Connect Hive Wallet",
    connectCosmos: "Connect Keplr Wallet",
    install: "Install",
    transfer: "Transfer",
    asset: "Asset",
    nomination: "Vote Delegators ",
    contribution: "Crowdloan",
    balance: "balance",
    bond: "Bonding",
    unBond: "Unbonding",
    redeem: "Redeem",
    bindAmount: "Binding Amount",
    inputAmount: "Input Amount",
    receiveAddress: "Receiver Address",
    transferAmount: "Transfer Amount",
    unbindAmount: "Unbinding amount",
    intallKeplr: "Please install keplr"
  },
  date: {
    year: " Year ",
    month: " Month ",
    day: " Day ",
    hour: " Hour ",
    min: " Minute ",
    sec: " Second ",
  },
  transaction: {
    broadcasting: "Transaction Is Broadcasting.",
    inBlock: "Transaction included at blockHash.",
    contributeOk: "Contribution Success!",
    withdrawOk: "Withdraw Success!",
    nominateOk: "Nominate Success!",
    transactionOk: "Transaction Success!",
    depositOk: "Deposit Success",
    withdrawOk: "Decrease Deposit Success!",
    voteOk: 'Vote Success',
    unvoteOk: "Unvote Success",
    
  },
  stake: {
    stake: "Staking Mining",
    creaseDelegation: "Delegate",
    increaseDelegation: "Undelegate",
    getSp: "Get SP",
    get: "Get",
    stake: "Deposit",
    unStake: "Undeposit",
    staked: "Staked ",
  },
  tip: {
    approveSuccess: "Approval Success",
    loading: "Loading, Please wait",
    connectingPolkadot: "Connecting Polkadot network",
    noAuction: "No ongoing Auction ",
    noProject: "No ongoing Project",
    noNominations: "No Nominators Nodes",
    noContribuitons: "No Contribution Records",
    noCommunities: "No Registered Communities ",
    noCrowdloan: "No ongoing Crowdloan",
    noStakingProject: "No validators",
    picTip: "The recommended size is {size} ，smaller than 2M, support jpg、png、jpeg formats",
    tokenSafeTip: "Your asset would be locked into {symbol} official crowdloan pallet directly, not any accounts of Nutobx.",
    belowMinContribution: "Input is less than the minimum required contribution of {min}",
    belowMinBond: "Input is less than the minimum suggested bond amount of 0.1",
    wrongNominatorAddress: "Wrong Nominator Address",
    wrongTransferAddress: "Wrong Transfer Address",
    inputAddressType: "Please Input {relaychain} Address",
    insufficientBalance: "Insufficient Balance",
    editTip: "Uploading community information requires the use of your wallet signature, this operation will not cost you anything, please rest assured to use。",
    outOfCap: "Out of cap",
    tips: "Tips",
    error: "Error！",
    success: "Success!",
    warning: "Warning！",
    noNeedWithdraw: "No Need To Withdraw",
    comingSoon: "Coming Soon",
    clipboard: "Clipboard",
    copyAddress: "Copied address:  {address}",
    signing: "Signing",
    picUploadFail: "Pictures fail to upload, Please try again",
    uploadLogo: "Please upload logo",
    deloyTokenFail: "Token fail to deloy, Please try again",
    notContractAddress: "Please Provide Deploy Token Contract Address",
    registryAssetFail: "Registry Asset Fail",
    wrongStartBlockNum: "Start BlockNum must be larger than the current BlockNum",
    wrongStopBlockNum: "Stop BlcokNum must be larger than the Start BlockNum",
    youHaveCreatedCommunity: "You Have Created Community",
    pleaseFillData: "Please select delpoy asset and distribution policies",
    deployFactoryFail: "Factory Fail to delpoy, Please try agian",
    deploySuccess: "Deloy Success",
    createPoolSuccess: "Create pool success",
    updatePoolSuccess: 'Update pool ratios success',
    chargeSuccess: 'Charge success',
    storeTokenAddress: "Please confirm the information and store Token Address immediately",
    needName: "Please input Community Name",
    needWebsit: "Please input Community Website",
    needDescription: "Please input Community Description",
    needIcon: "Please upload Community Logo",
    needTokenIcon: "Please upload C-token Logo",
    needPoster: "Please Upload Community Poster",
    needTokenName: "Please input token name",
    needTokenSymbol: "Please input token symbol",
    completeCommunityInfoSuccess: "Community Information update successfully",
    wrongSubstrateAddress: "Please input correct {type} address",
    ratioError: "The total proportion of Staking Pool is 100",
    wrongApy: "Please input correct APR",
    wrongRewardNum: "Please input correct rewards",
    deployCommunitySuccess: "Deploy success!",
    communityNameLimit: "The community name cannot exceed {count} word",
    descriptionLimit: "The community description cannot exceed {count} words",
    needRightUrl: "Please input right url.",
    selectCowdloan: "Please select a crowdloan project.",
    inputRightBlogTag: "Please input a right Steem community tag. eg:hive-12345.",
    withdrawSuccess: "Withdraw success!",
    harvestSuccess: "Harvest success!",
    deploying: "Deploying, may take some time, please wait for a moment.",
    stopPoolOk: "Pool has been stopped",
    removePoolOk: "Pool has been removed",
    tryWithdrawOk: "Redeem success.",
    stopPoolTips: "Need set pool ratio to 0 before stop pool.",
    completeCommunityProposalConfigSuccess: "Community proposal config success",
    completeProposalSuccess: "Proposal success",
    completeVoteSuccess: "Vote success",
    needGameName: "Please input game name",
    gameNameLimit: "The game name cannot exceed{count}words",
    needGameType: "Please choose game type",
    completeGameSuccess: "Game Success",
    deployTokenTip: 'Attention: this is the pre-distribution amount mint to your own account, not total supply. Please be carefull.',
    attention: "Attention!",
    markdownTip: "You can use markdown format.",
    stopPoolAttention: 'Once you close the pool, it cannot be opened again. Please use this function with caution!',
    stakeTokenLogoTip: "This logo is set to the stake token {token} which you selected.",
    updateSocialSuccess: 'Update successful!',
    startHeightOut: 'The first start block must bigger than current block.',
    wrongSocialInfo: "Please fill right {type} link!",
    noPowerupNut: "Haven't power up NUT",
    needAscii: 'You can only input simple characters.',
    npRedeemProcess: 'No redeem process.',
    powerDownSuccess: 'Power down success',
    redeemSuccess: "Redeem Successfull"
  },
  nps: {
    nps: "Governance",
    npsTemp: "Show Peanut governance for example. Walnut governance is under development.",
    all: "All",
    pass: "Passed",
    pending: "Pending",
    unpass: "Rejected",
    rolling: "Voting",
    mine: "My publish",
    title: "Title",
    titleInputTip: "Please input title",
    startAndEndTime: "Start & end time",
    startTime: "Start time",
    endTime: "End time",
    details: "Details",
    detailsInputTip: "Please input proposal details.",
    proposalConfigLabel: "Proposal Config",
    voteProposal: "Vote Proposal",
    createProposal: "Create Proposal",
    noProposals: "No proposals",
    proposalTitle: "Title",
    proposalTitleInput: "Please input proposal title",
    proposalStart: "Start time",
    proposalEnd: "End time",
    proposalFirst_Block: "Start block",
    proposalEnd_Block: "Stop block",
    proposalBody: "Detail",
    proposalBodyPreview: "Preview",
    proposalBodyInput: "Please input detail",
    proposalNetwork: "Network",
    proposalNetworkInput: "Please select Network",
    proposalSymbol: "Symbol",
    proposalSymbolInput: "Please input symbol",
    proposalStrategies: "Strategies",
    proposalThreshold: "Threshold",
    proposalThresholdInput: "Please input threshold",
    proposalPassThreshold: "Pass threshold",
    proposalPassThresholdInput: "Please input pass threshold",
    proposalValidation: "Validation",
    proposalValidationInput: "Please input validation",
    proposalAgreeBtn: "Agree",
    proposalDisagreeBtn: "Disagree",
    propsalSureVote: "Are you sure",
    propsalVoteRight: "Your voting rights",
    propsalVoteRemind: "Are you sure {0}?",
    propsalVoteStatusWaitStart: "Wait",
    propsalVoteStatusDoing: "Voting",
    propsalVoteStatusEnd: "End",
    proposalInfo: "Proposal Info",
    proposalVoteNum: "Vote Number",
    proposalVoteResult: "Vote Result",
    proposalStatus: "Vote status",
    editProposalConfigBtn: "ProposalConfig",
    editStrategy: "Edit Strategy",
    validationWarning: {
      basic: {
        minScore: "You need to have a minimum of {0} {1} in order to submit a proposal.",
      },
      customValidation: "You need to pass the proposal validation in order to submit a proposal.",
    },
    remark: "Intro",
    downloadReport: "Download Report",
    completeContent: "Please fill all the blanks.",
    endLtStart: 'End block number must greater than start block',
    blockExceed: 'End block number must greater than current block number'
  },
  error: {
    error: "Error",
    depositFail: "Deposit Fail",
    withdrawFail: "Withdraw Fail",
    changeDepsitFail: "Change deposition fail",
    delegateFail: "Delegate fail",
    changeDelegateFail: "Change delegation fail",
    haveCreatedCommunity: "You already created a community. Please refresh for try!",
    approveFail: "Approve fail",
    accountChanged: "Steem and Tron address mismatch，Please confirm whether the wallet account is switched",
    delegateerror: "Delegation Error！",
    tryrefreshpage: "Error，please refresh page and try again！",
    needkeychainandtronlink: "Need to install Steem Keychain and TronLink！",
    needkeychain: "Please install Steem Keychain",
    needtronlink: "Please install TronLink",
    insufficientEnerge: "Not enough energy",
    transferFail: "Transfer Fail",
    unlockKeychain: "Please unlock Steem Keychain",
    unlockWallet: "Plase unlock tronlink",
    steemLoginFail: "Steem login fail",
    hiveLoginFail: "Hive login fail",
    illegalTronAddress: "Invalid tron address",
    inputError: "Input wrong value",
    inputOverflow: "Input overflow",
    inputOutOfRange: "Please input value between {lowerPnutAmount}-{upperPnutAmount}",
    inputLinkIllegal: "Please input correct link",
    notEnoughFee: "Insufficient fee",
    insufficentPnut: "Insufficient PNUT",
    insufficentErc20: "Insufficient {symbol}",
    hasVoted: "Your post has been voted by nutbox.mine",
    pleaseRetry: "Please try agian",
    signatureFailed: "Signature fail，Please refresh the page and retry",
    serveError: "Server Error",
    networkError: "Network Error",
    noStakingFactory: "You have not created a community yet",
    blockChainError: "Contract invocation error",
    cancelSigning: "Signing cancel",
    unknow: "Unkonwn error",
    notConnectWallet: "Wallet not Connect",
    unlockWallet: "Please unlock Wallet",
    wrongChainId: "Wrong ChainID",
    wrongETHAddress: "Wrong ETH Address",
    wrongInputDevRatio: "Please input number between 0-100",
    notTokenContract: "The address you input is not a BEP20 contract address.",
    transactionFail: "Transaction fail!",
    assetHasRegisterd: "Asset has been registered by someone.",
    steemAccountEmpty: "Steem account missing",
    steemAccountActiveKeyEmpty: "Steem account or active key missing",
    hiveAccountEmpty: "Hive account missing",
    hiveAccountActiveKeyEmpty: "Hive account or active key missing",
    tokenDeploying: "There's token in deploying, please wait or refresh page to try.",
    wrongConfirmInfo: "Wrong confirm info!"
  },
  // navigator
  router: {
    information: "Information",
    profile: "Profile",
    asset: "Asset",
    pool: "Pool",
    social: "Social Info",
    governance: "Governance",
    staking: "Staking",
    blog: "Blog",
    member: "Member"
  },
  // placeHolder tip
  placeHolder: {
    inputName: "Please input Community Name",
    inputLink: "Please input Community Website Link",
    inputDesc: "Plese input Community Description ",
    inputApy: "Please input Apr",
    inputStartBlock: "Please input start block",
    inputStopBlock: "Please input stop block",
    inputMintAmount: "Please input mint number",
    inputDevRatio: "Please input DAO fund ratio",
    remarkInput: "Please input introduce",
    confirmInfo: 'Please input "{info}" to confirm.',
    inputPoolName: 'Please input pool name.',
    inputDelegatee: 'Please input delegatee account',
    inputDevAddress: 'Please input new DAO fund address'
  },
  community: {
    community: "Community",
    social: "Social",
    communityList: "Community List",
    communityTokenList: "C-Token List",
    communityName: "Name",
    communityLink: "Offcial Link",
    communityDesc: "Description",
    communityLogo: "Logo",
    cTokenLogo: "C-Token Logo",
    communityThemeColor: "Theme color",
    communityPoster: "Poster",
    communityBalance: "Community Balance",
    communityAsset: "Community Token",
    fundAddress: "Fund Address",
    fundRatio: "Fund Ratio",
    totalDeposit: 'Total amount',
    currentBlock: 'Current block',
    noJoinedCommunity: 'No joined communities',
    noJoinedPool: "No joined pools",
    exploreCommunity: 'Explore Communities',
    retainedRevenue: 'Retained Revenue',
    insufficientBalance: "Insufficient Community Balance",

    noCommunity: "You have no community yet",

    startBlock: "Start block",
    stopBlock: "Stop block",
    mintAmount: "Mint amount",

    opened: 'Opened',
    closed: 'Closed'
  },
  pool: {
    updatePoolRatios: "Configure Pools",
    addPool: "Add Pool",
    noPools: 'No pool created',
    userCount: 'User count',
    totalDeposit: 'Total deposit',
    tvl: 'TVL',
    closePool: "Close",
    ratio: 'Ratio',
    opened: 'Active',
    closed: 'Closed'
  },
  gauge: {
    voterCount: 'Voters',
    totalVoted: 'Total Voted',
    vote: "Vote",
    unvote: "Unvote"
  },
  asset: {
    tokenName: "Name",
    tokenSymbol: "Symbol",
    tokenAddress: "Token address",
    tokenId: "Token ID",
    wrongTokenId: "Token ID must be a integer number.",
    price: "Price",
    totalSupply: 'Supply',
    cap: 'Mkt Cap',
    distributionAmount: "Premine amount",
    notErc1155: "Token is not a ERC1155 token."
  },
  treasury: {
    treasuryAddress: "Treasury Address",
    daoTreasury: "DAO Treasury",
    treasuryAsset: "Treasury Asset",
    noCreatedTip: "You can create your community treasury.",
    createNote: "NOTE: once you create the treasury, anyone can redeem assets from DAO treasury use cToken as a share of treasury asset."
  }
};
