import {
    client,
    restClient
} from './index';
import store from '@/store'
import {
    gql
} from 'graphql-request'
import {
    USE_THE_GRAPH
} from '@/config';
import {
    ethers
} from 'ethers';
import {
    parseOperationStructure
} from './utils'

export const getISOPools = async () => {
    const query = gql`
    {
        communities{
            id
            treasury
            owner{
                id
            }
            pools(where: {status: OPENED, chainId_not: 0}) {
                id
                asset
                name
                poolFactory
                chainId
                totalAmount
            }
        }
    }
  `
    try {
        const data = await client.request(query)
        if (data && data.communities) {
            return data.communities
        }
    } catch (e) {
        console.log('Get community from graph fail:', e);
    }
}

export async function getAllStakeAtomToNutboxOperations() {
    let allOps = []
    let timestamp = parseInt((new Date().getTime()) / 1e3).toString()
    while (true) {
        const data = await getStakeAtomOperationsByTimestamp(timestamp)
        allOps = allOps.concat(data)
        if (data.length < 1000) {
            break;
        }
        timestamp = data[data.length - 1].timestamp
    }
    return allOps
}

export async function getStakeAtomOperationsByTimestamp(timestamp) {
    const query = gql`
        query UserOperationHistories($timestamp: String!){
            userOperationHistories(orderBy: timestamp, orderDirection:desc, where: {asset: "0x8df9fcbcc63a18290d10582585bece22137f59af", timestamp_lt: $timestamp}) {
                user
                asset
                pool{
                    id
                    name
                }
                amount
                timestamp
            }
        }
    `
    try {
        const ops = await client.request(query, {
            timestamp
        })
        if (ops && ops.userOperationHistories) {
            return ops.userOperationHistories
        }
    } catch (e) {
        console.log('Get all users fail', e);
    }
}
