module.exports = {
  commen: {
    userlogin: "로그인",
    steemAccoutPlaceHolder: "스팀 계정",
    hiveAccountPlaceHolder: "하이브 계정",
    login: "로그인",
    loginByKeychain: "키체인으로 로그인 하기",
    loging: "로그인 중...",
    cancel: "취소",
    confirm: "확인",
    add: "추가하기",
    update: "업데이트",
    crowdstaking: "스테이킹",

    apy: "APR",
    withdraw: "수확하기",
    balance: "잔고",
    approveContract: "컨트랙트 승인",
    max: "최대치",
    delegatecharge: "임대 수수료",
    logout: "로그아웃",
    input: "입력",
    copy: "복사",
    complete: "완료",
    now: "지금",
    connectMetamask: "지갑 연결",

    whitePaper: "백서",
    nps: "넛박스 프로포절 시스템",
    contract: "컨트랙트 주소",
    social: "소셜",
    docs: "문서",
    discord: "디스코드",
    blog: "블로그",
    contractAddr: "컨트랙트 주소",
    search: "검색",
    dashboard: "대쉬보드",
    language: "언어",
    zh: "中文",
    en: "English",
    kr: "한국어",
    my: "Malaysia",
    tr: "Türkçe",
    es: "Español",
    jp: "日本語",
    goToHome: "홈화면으로 돌아가기",
    home: '홈',
    dappStore: '댑(Dapp) 스토어'
  },
  tutorial: {
    createProcess: "프로세스 생성",
    step0: "Step0: 자산 등록하기",
    step1: "Step1: 자산 선택하기 & 분배 규칙 설정하기",
    step2: "Step2: 커뮤니티 정보 작성하기",
    step3: "Step3: 스테이킹 풀 생성하기",
    demoVideo: "데모 비디오",
    getStart: "시작하기",
    watchVideo: "데모 비디오 시청하기",
    videoTip1:
      "몇 분이 되지 않는 이 비디오를 통해 우리는 당신에게 스테이킹 풀 생성 프로세스를 처음부터 끝까지 안내합니다.",
    videoTip2: "Play 버튼을 클릭하여 비디오 시청하기",
  },
  wallet: {
    wallet: "지갑",
    connectSteem: "스팀 지갑 연결하기",
    connectHive: "하이브 지갑 연결하기",
    install: "설치",
    transfer: "전송",
    asset: "자산",
    nomination: "투표 임대인 ",
    contribution: "크라우드론",
    balance: "잔고",
    bond: "결합",
    unBond: "결합 해제",
    redeem: "회수하기",
    bindAmount: "결합 수량",
    inputAmount: "입력 수량",
    receiveAddress: "수령인 주소",
    transferAmount: "전송 수량",
    unbindAmount: "결합 해제 수량",
  },
  cs: {
    crowdstaking: "크라우드스테이킹",
    deposit: "맡기기",
    steemDelegate: "스팀 임대",
    hiveDelegate: "하이브 임대",
    delegate: "임대",
    crowdloan: "크라우드론",
    nomination: "지명하기",
    community: "커뮤니티",
    nominate: "투표",
    nominated: "투표가 되었음",
    bond: "결합",
    bondedAmount: "결합이 되었음",
    available: "가능한 자본",
    bondAndNominate: " 결합 및 투표",
    cancel: "취소",
    bondInfo1:
      "추후 발생할 거래 수수료에 대비하여 평소 일정량의 암호화폐를 유지하세요",
    bondInfo2: "최소 결합 수량은 {최소치} {상징}.",
    bondInfo3: "결합 해제 프로세스는 {몇} 일 소요될 예정입니다.",
    confirm: "확인",
    cancelValidorsInfo:
      "당신이 다른 노드들에 투표하기 위해소 당신이 기존 투표한 최소 {}노드를 최소해 주세요.",
  },
  validator: {
    validator: "검증자",
    commission: "수수료",
    otherStake: "다른 사람들의 스테이킹",
    ownStake: "나의 스테이킹",
    waiting: "대기",
  },
  date: {
    year: " 연 ",
    month: " 월 ",
    day: " 일 ",
    hour: " 시간 ",
    min: " 분 ",
    sec: " 초 ",
  },
  cl: {
    crowdloan: "크라우드론",
    community: "커뮤니티",
    contribute: "기여하기",
    withdraw: "인충하기",
    youWillWithdraw: "당신은 인출할 예정입니다",
    confirm: "확인 및 서명",
    amount: "수량",
    inputAmount: "스테이킹 수량",
    nominator: "지명자",
    inputNominator: "당신의 지명자의 주소",
    optional: "(선택 사항)",
    completed: "완료되었음",
    leasePeriod: "임대 기간",
    countDown: "카운트다운",
    rewards: "보상",
    auctionPlan: "크라우드론 보상",
    auctionInfo: "크라우드론 세부사항",
    joinAuction: "크라우드론 참여하기",
    raised: "모금됨",
    fund: "자금",
    progress: "진행",
    contributors: "기여자들",
    contributed: "나의 기여",
    myBalance: "{토큰}의 잔고",
    Active: "액티브",
    Winner: "우승자",
    Retired: "탈락한",
    Completed: "완료됨",
    chain: "체인",
    trieIndex: "Trie 인덱스",
    contributeAmount: "기여 수량",
    contributeTime: "기여 시간",
  },
  transaction: {
    broadcasting: "거래가 송출중입니다.",
    inBlock: "블록해시에 거래가 포함되었습니다.",
    contributeOk: "기여 성공!",
    withdrawOk: "인출 성공!",
    nominateOk: "지명 성공!",
    transactionOk: "거래 성공!",
    depositOk: "맡기기 성공",
    withdrawOk: "인출 성공!",
  },
  stake: {
    stake: "스테이킹 마이닝",
    creaseDelegation: "임대",
    increaseDelegation: "임대 해제",
    getSp: "SP 얻기",
    get: "얻기",
    stake: "맡기기",
    unStake: "맡기기 취소",
    staked: "스테이킹 되어있는 상태",
  },
  tip: {
    approveSuccess: "승인 성공",
    loading: "로딩중, 잠시만 기다려 주십시오.",
    connectingPolkadot: "폴카닷 네트워크 연결중",
    noAuction: "경매 진행 중이 아닙니다 ",
    noProject: "진행 중인 프로젝트가 아닙니다",
    noNominations: "지명자 노드가 아닙니다",
    noContribuitons: "기여 기록이 없습니다",
    noCommunities: "등록된 커뮤니티가 아닙니다 ",
    noCrowdloan: "진행 중인 크라우드론이 아닙니다",
    noStakingProject: "검증자가 아닙니다",
    tokenSafeTip:
      "당신의 자산은 넛박스의 계정이 아닌, {상징} 공식 크라우드론 ‘지갑’에 직접적으로 맡겨질 예정입니다.",
    belowMinContribution:
      "입력하신 수량이 다음 최소 기여 가능 수량보다 적습니다 {최소 수량}",
    belowMinBond: "입력하신 수량이 권고되는 최소 수량인 0.1 보다 적습니다.",
    wrongNominatorAddress: "잘못된 지명 주소",
    wrongTransferAddress: "잘못된 전송 주소",
    inputAddressType: "{릴레이체인} 주소를 입력해 주세요",
    insufficientBalance: "잔고 불충분",
    outOfCap: "수용량 초과",
    tips: "팁",
    error: "에러！",
    success: "성공 !",
    warning: "경고！",
    noNeedWithdraw: "인출할 필요가 없습니다.",
    comingSoon: "커밍순",
    clipboard: "클립보드",
    copyAddress: "복사된 주소:  {주소}",
    signing: "서명중",
    picUploadFail: "사진 업로드 실패, 다시 시도해 주세요",
    uploadLogo: "로고를 업로드해 주세요",
    deloyTokenFail: "토큰 배치에 실패하였습니다, 다시 시도해 주세요",
    notContractAddress: "토큰 배치 컨트랙트 주소를 입력해 주세요",
    registryAssetFail: "자산 등록 실패",
    registryAssetSuccess: "자산 등록 성공",
    wrongStartBlockNum:
      "시작하는 블록 개수는 현재의 블록 개수보다 더 커야합니다.",
    wrongStopBlockNum: "정지하는 블록 개수는 시작하는 블록 개수보다 더 커야합니다.",
    youHaveCreatedCommunity: "당신은 커뮤니티를 만들었습니다.",
    pleaseFillData: "배치 자산과 분배 정책을 선택해 주세요.",
    deployFactoryFail: "팩토리가 배치에 실패했습니다, 다시 시도해 주세요.",
    deployFactorySuccess: "팩토리가 배치에 성공했습니다, 잠시만 기다려 주세요.",
    deploySuccess: "배치에 성공했습니다.",
    storeTokenAddress:
      "정보를 확인 및 토큰 주소 저장을 즉시 해 주십시오.",
    needName: "커뮤니티 이름을 입력해 주세요",
    needWebsit: "커뮤니티 웹사이트를 입력해 주세요",
    needDescription: "커뮤니티 설명을 입력해 주세요",
    needIcon: "커뮤니티 로고를 업로드 해 주세요",
    needPoster: "커뮤니티 포스터를 업로드 해 주세요",
    completeCommunityInfoSuccess: "커뮤니티 정보가 성공적으로 업데이트 됐습니다",
    wrongSubstrateAddress: "정확한 {타입} 주소를 입력해 주세요",
    ratioError: "총 스테이킹 비율은 100입니다.",
    wrongApy: "정확한 APR를 입력해 주세요",
    wrongRewardNum: "정확한 리워드량을 입력해 주세요",
    createBlogSuccess: "블로그 생성 성공!",
    communityNameLimit: "커뮤니티의 이름은 {몇} 개의 단어를 초과할 수 없습니다.",
    needRightUrl: "올바른 url 주소를 입력해 주세요.",
    selectCowdloan: "크라우드론 프로젝트를 선택해 주세요.",
    inputRightBlogTag:
      "올바른 스팀 커뮤니티 태그. 예:hive-12345.를 입력해 주세요",
    withdrawSuccess: "수확 성공!",
    deploying: "배치중입니다, 시간이 다소 소요될 수 있습니다, 잠시만 기다려 주십시오.",
    stopPoolOk: "풀이 정지되었습니다",
    removePoolOk: "풀이 제거되었습니다",
    tryWithdrawOk: "회수에 성공했습니다.",
    stopPoolTips: "풀이 정지되기 이전 풀의 비율을 0으로 설정해야 합니다.",
    completeCommunityProposalConfigSuccess: "커뮤니티 프로포절 구성 성공",
    completeProposalSuccess: "프로포절 성공",
    completeVoteSuccess: "투표 성공",
    needGameName: "게임 이름을 입력해 주세요",
    gameNameLimit: "게임 이름은 {몇} 개의 단어를 초과할 수 없습니다.",
    needGameType: "게임 유형을 선택해 주세요",
    completeGameSuccess: "게임 성공",
    deployTokenTip: '주의: 이것 은 총 공 급 량 이 아니 라 귀하 계좌 의 사전 분배 금액 입 니 다.조심 하 세 요.',
    attention: '주의 하 다.',
    stopPoolAttention: '일단 연못 을 멈 추 면, 그것 은 다시 열 수 없 을 것 이다.이 기능 을 신중하게 사용 하 세 요!'
  },
  nps: {
    nps: "거버넌스",
    npsTemp:
      "피넛 거버넌스를 예시로 보여주세요. 월넛 거버넌스는 현재 개발 중입니다.",
    all: "모두",
    pass: "통과",
    pending: "계류중",
    unpass: "거절",
    rolling: "보팅중",
    mine: "나의 발행",
    title: "제목",
    titleInputTip: "제목을 입력해 주세요",
    startAndEndTime: "시작 & 종료 시간",
    startTime: "시작 시간",
    endTime: "종료 시간",
    details: "세부 사항",
    detailsInputTip: "프로포절의 세부사항을 입력해 주세요.",
    proposalConfigLabel: "프로포절 구성",
    voteProposal: "투표 구성",
    createProposal: "프로포절 생성",
    noProposals: "프로포절 없음",
    proposalTitle: "제목",
    proposalTitleInput: "프로포절 제목을 입력해 주세요",
    proposalStart: "시작 시간",
    proposalEnd: "종료 시간",
    proposalFirst_Block: "시작 블록",
    proposalEnd_Block: "종료 블록",
    proposalBody: "세부사항",
    proposalBodyPreview: "미리보기",
    proposalBodyInput: "세부사항을 입력해 주세요",
    proposalNetwork: "네트워크",
    proposalNetworkInput: "네트워크를 선택해 주세요",
    proposalSymbol: "상징",
    proposalSymbolInput: "상징을 입력해 주세요",
    proposalStrategies: "전략들",
    proposalThreshold: "기준치",
    proposalThresholdInput: "기준치를 입력해 주세요",
    proposalPassThreshold: "기준치 통과",
    proposalPassThresholdInput: "통과 기준치를 입력해 주세요",
    proposalValidation: "검증",
    proposalValidationInput: "검증을 입력해 주세요",
    proposalAgreeBtn: "동의",
    proposalDisagreeBtn: "미동의",
    propsalSureVote: "이대로 진행할까요?",
    propsalVoteRight: "당신의 투표 권리",
    propsalVoteRemind: "이대로 진행할까요?{0}?",
    propsalVoteStatusWaitStart: "기다려 주세요",
    propsalVoteStatusDoing: "투표중",
    propsalVoteStatusEnd: "투표 종료",
    proposalInfo: "프로포절 종료",
    proposalVoteNum: "투표 갯수",
    proposalVoteResult: "투표 결과",
    proposalStatus: "투표 상태",
    editProposalConfigBtn: "프로포절 구성",
    editStrategy: "전략 수정",
    validationWarning: {
      basic: {
        minScore:
          "당신은 프로포절을 제출하기 위하여 최소 {0}{1}가 있어야 합니다.",
      },
      customValidation:
        "당신은 프로포절을 제출하기 위하여 프로포절 검증을 통과하여야 합니다.",
    },
    remarkInput: "소개를 입력해 주세요",
    remark: "소개",
    markdownTip: "당신이 사용하는 마크다운 포맷.",
    downloadReport: "리포트를 다운로드 하세요",
  },
  error: {
    error: "에러",
    depositFail: "맡기기 실패",
    withdrawFail: "인출 실패",
    changeDepsitFail: "맡기기 실패 전환하기",
    delegateFail: "임대 실패",
    changeDelegateFail: "임대 실패 전환하기",
    approveFail: "승인 실패",
    accountChanged:
      "스팀과 트론의 주소가 일치하지 않습니다. 당신의 지갑이 전환되었는지 다시 한번 확인해 주십시오.",
    delegateerror: "위임 오류！",
    tryrefreshpage: "오류，페이지 새로고침 후 다시 시도하세요！",
    steemloginerr: "스팀 로그인이 실패하였습니다!",
    needkeychainandtronlink: "스팀키체인과 트론링크를 설치해야 합니다 ! ",
    needkeychain: "스팀키체인을 설치해 주세요",
    needtronlink: "트론링크를 설치해 주세요",
    insufficientEnerge: "에너지가 충분하지 않습니다.",
    transferFail: "전송 실패",
    unlockKeychain: "스팀키체인 잠금을 해제해 주세요",
    unlockWallet: "트론링크 잠금을 해제해 주세요",
    steemLoginFail: "스팀 로그인 실패",
    hiveLoginFail: "하이브 로그인 실패",
    illegalTronAddress: "유효하지 않은 트론 주소",
    inputError: "잘못된 값을 입력했습니다",
    inputOverflow: "오버플로를 입력해 주세요",
    inputOutOfRange:
      "{Pnut양의 하한}-{Pnut양의 상한}의 값을 입력해 주세요",
    inputLinkIllegal: "올바른 링크를 입력해 주세요",
    notEnoughFee: "수수료 불충분",
    insufficentPnut: "Pnut 불충분",
    hasVoted: "당신의 포스트는 이미 nutbox.mine에 의해 보팅되었습니다.",
    pleaseRetry: "다시 시도해 주십시오",
    signatureFailed: "서명 실패，페이지 새로고침 후 다시 시도해 주세요",
    serveError: "서버 에러",
    networkError: "네트워크 에러",
    noStakingFactory: "당신은 커뮤니티를 아직 생성하지 않았습니다.",
    blockChainError: "컨트랙트 발동을 함에 있어서 오류가 발생했습니다.",
    cancelSigning: "서명 취소",
    unknow: "미확인 에러",
    notConnectWallet: "지갑이 연결되지 않았습니다.",
    unlockWallet: "지갑을 잠금 해제 해 주세요 ",
    wrongChainId: "잘못된 체인 아이디 ",
    wrongETHAddress: "잘못된 ETH 주소 ",
    wrongInputDevRatio: "0-100 사이의 숫자를 입력해 주세요",
    notTokenContract: "당신이 이볅한 주소는 ERC20 컨트랙트 주소가 아닙니다.",
    transactionFail: "거래 실패!",
    assetHasRegisterd: "자산이 다른 사람에 의해서 등록됐습니다.",
    steemAccountEmpty: "스팀 계정이 누락돼 있습니다. ",
    steemAccountActiveKeyEmpty: "스팀 계정 혹은 액티브 키가 누락돼 있습니다. ",
    hiveAccountEmpty: "하이브 계정이 누락돼 있습니다 ",
    hiveAccountActiveKeyEmpty: "하이브 계정 혹은 액티브 키가 누락돼 있습니다. ",
    tokenDeploying:
      "토큰이 배치중입니다.  잠시만 기다려 주시거나 페이지 새로고침 후 다시 시도해 주십시오. ",
  },
  community: {
    community: "커뮤니티",
    percentage: "백분율(%)",
    communityInfo: "정보",
    social: "소셜",
    pool: "풀",
    edit: "수정 ",
    create: "완료 ",
    communityList: "커뮤니티 리스트",
    communityTokenList: "커뮤니티토큰 리스트",
    communityName: "커뮤니티 이름",
    communityLink: "공식 링크",
    communityDesc: "설명",
    communityLogo: "로고",
    communityThemeColor: "커뮤니티 대표 색깔",
    communityPoster: "포스터",
    communityBalance: "커뮤니티 잔고",
    communityAsset: "커뮤니티 자산",
    commit: "약속",
    inputName: "커뮤니티 이름을 입력해 주세요",
    inputLink: "커뮤니티 웹사이트 링크를 입력해 주세요",
    inputDesc: "커뮤니티 설명을 입력해 주세요",
    inputApy: "APR를 입력해 주세요",
    uploadLogo: "로고를 업로드 해주세요",
    picTip:
      "권장하는 사이즈는 {}입니다. 2M 보다 작아야 하며, jpg, png, jpeg 형태로 업로드 해주세요 ",
    uploadPoster: "포스터 업로드",
    poster: "포스터",
    noCommunity: "당신은 아직 커뮤니티가 없습니다",
    gotoCreate: "지금 만들러 가기",
    editTip:
      "커뮤니티 정보를 입력하는 것은 당신의 지갑 서명을 필요로 합니다. 이 작업은 당신이 따로 지불하지 않으셔도 됩니다.  안심하고 사용하셔도 됩니다. ",
    sign: "서명 및 업로드 ",
    createCommunity: "당신의 배타적인 스테이킹 이코노미를 만드세요 ",
    step1: "1단계",
    step2: "2단계",
    yourCTokenId: "1단계：자산 선택 ",
    settingTokenDistribution: " 2단계：자산 분배 관련 설정하기 ",
    tokenEra: "현재 정책에 따른 총 분배 ",
    currentBlock: "현재 블록 높이 ",
    startBlock: " 시작 블록 ",
    inputStopBlock: "멈추는 블록 입력하기 ",
    inputBlockReward: "블록 당 리워드 입력 ",
    stopBlock: "블록 멈추기 ",
    rewardAmount: "리워드양 ",
    comfirmAdd: "추가 확정하기 ",
    personal: "나의 등록된 자산 ",
    official: "공식적으로 제공된 자산 ",
    addPoolSuccess: "스테이킹 풀이 성공적으로 추가됐습니다 ",
    updatePoolSuccess: "스테이킹 풀이 성공적으로 업데이트되었습니다. ",
    dashboard: "대시보드 ",
    setting: "설정 ",
    updatePools: "스테이킹풀 데이터를 조절해 주세요 ",
    addPool: "스테이킹 풀 추가하기 ",
    stopPool: "풀 멈추기 ",
    withdrawPool: "풀 지우기 ",
    removePool: "풀 제거하기 ",
    Active: "활성화 ",
    Stopped: "멈춤 ",
    CanRemove: "멈춤 ",
    Removed: "제거됨 ",
    noPools: "스테이킹 풀이 아직 없습니다 ",
    userCount: "유저 숫자 ",
    totalDeposit: "총 스테이킹",
    totalDepositDollor: "잠긴 가치",
    hasMined: "토큰이 채굴되었습니다 ",
    more: "더 ",
    join: "참여하기 ",
    createYourCommunity: "당신의 커뮤니티를 생성하세요 ",
    communityDashboard: "당신의 커뮤니티를 관리하세요 ",
    charge: "요금",
    communityCharge: "커뮤니티 요금",
    confirmCharge: "요금 확정하기 ",
    chargeSuccess: "요금 지불 성공 ",
    devAddress: "커뮤니티 자금 주소 ",
    devRatio: "커뮤니티 수수료 비율 ",
    inputDevRatio: " 0-100 사이의 숫자를 입력해 주세요",
    inputDevAddress: " 커뮤니티 dev주소를 입력해 주세요",
    publishPool: " 스테이킹 풀 발행하기",
    unPublished: " 미발행",
    publishBlog: " 블로그 발행하기",
    communityBlog: " 커뮤니티 블로그",
    socialMedial: " 소셜미디어",
    blogTag: " 블로그 태그",
    createBlog: " 블로그 생성하기",
    createBlogMemo:
      " 당신의 블로그 태그와 프라이빗 키는 아래 목록과 같습니다. 주의 깊게 이들을 보존해 주세요. 저희는 수탁 서비스를 제공하지 않습니다. 블로그 계정 생성 절차에 사용되는 서비스 비용은 3 스팀입니다. ",
    blogMainPassword: "비밀번호 ",
    publishBlogSuccess: "블로그 발행에 성공하였습니다!",
    distributionLimit: "최대 분배는 6입니다 ",
    bindBlog: "당신의 커뮤니티 블로그를 결합해 주십시오 ",
    bindBlogMemo: "eg:hive-12345와 같은 당신의 스팀 커뮤니티 태그를 결합해 주십시오",
    inputBlogTag: "당신의 스팀 커뮤니티 태그를 입력해 주십시오",
    updateSocialSuccess: "소셜 정보 업데이트 성공 !",
    createNeedStake: "새로운 풀 생성은 당신의 : 스테이킹을 필요로 합니다. ",
    notYourCommunity: "잘못된 커뮤니티 정보",
  },
  asset: {
    asset: "자산Asset",
    network: "네트워크",
    native: "초기 자산",
    foreign: "외부 자산",
    registerCtokenTitle: "당신의 커뮤니티 자산을 등록해 주세요",
    registerTitle: "당신의 배타적인 자산을 등록해 주세요",
    yourSteemAccount: "당신의 스팀/하이브 계정",
    assetName: "이 자산의 이름을 입력해 주세요",
    assetProperties: "자산 속성",
    register: "등록",
    homeLocation: "홈 토큰 위치",
    tokenName: "토큰 이름",
    tokenSymbol: "토큰 상징",
    distributionAmount: "분배 양",
    inputHomeLocation: " ERC20 주소를 입력해 주세요 ",
    inputTokenName: " 토큰 이름을 입력해 주세요",
    inputTokenSymbol: " 토큰 상징을 입력해 주세요",
    inputDistributionAmount: " 분배 양을 입력해 주세요",
    notDeploy: " 아직 토큰이 없으십니까?",
    noTokens: " 커뮤니티 토큰을 생성하지 않았습니다.",
    deployOne: " 하나를 배치해 주세요",
    deploy: " 배치하다",
    deployYourToken: " 하나의 토큰을 배치하다",
    notRegister: " 등록된 자신이 아직 없으신가요?",
    registerOne: " 하나를 등록해 주세요",
    registerCAsset: " 커뮤니티 자산을 등록하세요",
    inputAccount: " 당신의 계정을 입력해 주세요",
    inputAssetName: " 자산의 이름을 입력해 주세요",
    parachainId: "ParahainID 파라체인 ID",
    inputParachainId: " 파라체인 ID를 입력해 주세요",
    trieIndex: " Trie 인덱스",
    inputTrieIndex: " Trie 인덱스를 입력해 주세요",
    communityAddress: " 커뮤니티 주소(DOT 주소)",
    inputCommunityAddress: " 커뮤니티 DOT 주소를 입력해 주세요",
    endingBlock: "자산 유효 기간 ",
    inputEndingBlock:
      "자신이 유효하지 않을 경우 멈추는 블록 높이를 입력해 주세요",
    registerSteemTab: "스팀/하이브 결합 자산을 등록해 주세요",
    registerCrowdloanTab: "폴카닷/쿠사마 크라우드론 결합 자산을 등록해 주세요",
    registerValidatorTab: "폴카닷/쿠사마 검증자 노드 결합 자산을 등록해 주세요",
    validatorNodeAddress: "검증자 노드 주소 ",
    inputValidatorNode: "검증자 노드 주소를 입력해 주세요 ",
    delegation: "임대 ",
    crowdloan: "크라우드론 ",
    validator: "검증자 노드 ",
    addPool: "스테이킹 풀 추가하기 ",
    updatePool: "스테이킹 풀 조정하기 ",
    poolRatios: "스테이킹 풀 수익률 ",
    poolInfo: "스테이킹 풀 정보 ",
    stakingAsset: "스테이킹 자산 ",
    selectStakingAsset: "스테이킹 자산을 선택해 주세요 ",
    isHomeAsset: "내부(home) 자산",
    isForeignAsset: "외부(foreign) 자산",
    poolName: "스테이킹 풀 이름 ",
    inputPoolName: "스테이킹 풀 이름을 입력해 주세요",
    cap: "시가총액 ",
    price: "가격",
    totalSupply: "총공급량",
  },
  game: {
    game: '게임',
    recommend: '추천',
    popular: '인기',
    others: '기타',
    noGames: '게임 없음',
    create: "생성",
    edit: "편집",
    gameName: "게임 이름",
    gameLink: "페이지 링크",
    gameDesc: "설명",
    gameCategory: "범주",
    gameLogo: "로고",
    gamePoster: "포스터",
    inputGameName: "게임 이름을 입력해 주세요 ",
    inputGameLink: "게임의 공식 페이지 링크를 입력해 주세요 ",
    inputGameDesc: "당신의 게임을 설명해 주세요 ",
    noGame: "게임이 존재하지 않습니다 ",
  },
};

