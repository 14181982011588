<template>
    <div class="scroll-content">
        <div class="community-info-card mb-5 text-left pl-3 pr-3">
            <div class="custom-form c-card">
                <b-form-group class="mb-4" label-class="overflow-hidden font14 line-height14 font-bold"
                    label-cols-md="3" content-cols-md="9" label="Cosmos validator">
                    <div class="d-flex">
                        <b-form-input v-model.trim="atomValidator"
                            placeholder="Please input atom validator address"></b-form-input>
                        <button class="primary-btn w-25 ml-2" :disabled="loadingPoolData" @click="search('atom')">
                            <b-spinner small type="grow" v-show="loadingPoolData"></b-spinner>
                            search
                        </button>
                    </div>
                </b-form-group>
                <!-- atom data list -->
                <template v-if="atomData && atomData.length > 0">
                    <b-form-group class="mb-3" label-class="overflow-hidden font12 line-height12"
                        label-cols-md="3" content-cols-md="9" label="">
                        <div class="col-data-container col-data-title mb-2">
                            <div class="col-data">
                                Community Name
                            </div>
                            <div class="col-data">
                                Total Staked
                            </div>
                            <div class="col-data">
                                Percentage
                            </div>
                        </div>
                        <div class="col-data-container"  v-for="c of atomData" :key="c.id + 'atom'">
                            <div class="col-data">
                                {{ c.info.name }}
                            </div>
                            <div class="col-data">
                                {{ c.atomAmount | amountForm }}
                            </div>
                            <div class="col-data">
                                {{ (totalAtom === 0 ? 0 :c.atomAmount / totalAtom * 100).toFixed(2) }}%
                            </div>
                        </div>
                    </b-form-group>
                    <b-form-group class="mb-3" label-class="overflow-hidden font14 line-height14"
                        label-cols-md="3" content-cols-md="9" label="Total">
                        <div class="col-data-container">
                            <div class="col-data"></div>
                            <div class="col-data">{{ totalAtom | amountForm }}</div>
                            <div class="col-data">100%</div>
                        </div>
                    </b-form-group>
                </template>
                <!-- atom distribute -->
                <div class="text-center" v-show="atomData && atomData.length > 0">
                    <button v-if="isLoadingApproveAtom || !atomApprovement" class="primary-btn w-25" @click="approve('atom')" :disabled="isLoadingApproveAtom || isApprovingAtom">
                        <b-spinner small type="grow" v-show="isLoadingApproveAtom || isApprovingAtom"></b-spinner>
                        Approve Contract
                    </button>
                    <button v-else class="primary-btn w-25" @click="showAtomDistribute = true">
                        Distribute
                    </button>
                </div>
            </div>

            <!-- steem -->
            <div class="custom-form c-card mt-5">
                <b-form-group class="mb-4" label-class="overflow-hidden font14 line-height14 font-bold"
                    label-cols-md="3" content-cols-md="9" label="Steem delegatee">
                    <div class="d-flex">
                        <b-form-input v-model.trim="steemDelegatee" placeholder="Please input steem delegatee account">
                        </b-form-input>
                        <button class="primary-btn w-25 ml-2" :disabled="loadingPoolData" @click="search('steem')">
                            <b-spinner small type="grow" v-show="loadingPoolData"></b-spinner>
                            search
                        </button>
                    </div>
                </b-form-group>

                <!-- steem data list -->
                <template v-if="steemData && steemData.length > 0">
                    <b-form-group class="mb-3" label-class="overflow-hidden font12 line-height12"
                        label-cols-md="3" content-cols-md="9" label="">
                        <div class="col-data-container col-data-title mb-2">
                            <div class="col-data">Communtiy Name</div>
                            <div class="col-data">Total Staked</div>
                            <div class="col-data">Percentage</div>
                        </div>
                        <div class="col-data-container" v-for="c of steemData" :key="c.id + 'steem'">
                            <div class="col-data">
                                {{ c.info.name }}
                            </div>
                            <div class="col-data">
                                {{ c.steemAmount | amountForm }}
                            </div>
                            <div class="col-data">
                                {{ (totalSteem === 0 ? 0 :c.steemAmount / totalSteem * 100).toFixed(2) }}%
                            </div>
                        </div>
                    </b-form-group>
                    <b-form-group class="mb-3" label-class="overflow-hidden font14 line-height14"
                        label-cols-md="3" content-cols-md="9" label="Total">
                        <div class="col-data-container">
                            <div class="col-data"></div>
                            <div class="col-data">{{ totalSteem | amountForm }}</div>
                            <div class="col-data">100%</div>
                        </div>
                    </b-form-group>
                </template>
                <!-- steem distribute -->
                 <div class="text-center" v-show="steemData && steemData.length > 0">
                     <button v-if="isLoadingApproveSteem || !steemApprovement" class="primary-btn w-25" @click="approve('steem')" :disabled="isLoadingApproveSteem || isApprovingSteem">
                        <b-spinner small type="grow" v-show="isLoadingApproveSteem || isApprovingSteem"></b-spinner>
                        Approve Contract
                    </button>
                    <button v-else class="primary-btn w-25" @click="showSteemDistribute = true">
                        Distribute
                    </button>
                </div>
            </div>
        </div>
    <!-- atom tip -->
    <b-modal
      v-model="showAtomDistribute"
      modal-class="custom-modal"
      size="m"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="custom-form font20 line-height28">
        <div class="modal-title font-bold mb-2">
          {{ $t("operation.distribute") }}
        </div>
        <div class="mb-4">
          <div class="label mb-3 text-center">
            <span>
                You will distribute {{ inputAtomAmount }} ATOM to the treasury
            </span>
          </div>
          <div class="label mb-2 d-flex justify-content-between">
            <span></span>&nbsp;
            <span class="text-right">{{ $t("wallet.balance") }}:{{ adminBatomBalance | amountForm }} ATOM</span>
          </div>
          <div class="c-input-group c-input-group-bg mb-3">
            <input type="number" v-model="inputAtomAmount" placeholder="0"/>
          </div>
          <div class="col-data-container font14 col-data-title mb-1">
            <div class="col-data">
                Community Name
            </div>
            <div class="col-data">
                Percentage
            </div>
            <div class="col-data">
                Distribute Amount
            </div>
          </div>
          <div class="col-data-container" v-for="c of atomData" :key="c.id + 'steem-op'">
                        <div class="col-data">
                            {{ c.info.name }}
                        </div>
                        <div class="col-data">
                            {{ (totalAtom === 0 ? 0 : c.atomAmount / totalAtom * 100).toFixed(2) }}%
                        </div>
                        <div class="col-data">
                            {{ (totalAtom === 0 ? 0 : c.atomAmount * parseFloat(inputAtomAmount) / totalAtom) | amountForm }}
                        </div>
                    </div>
        </div>
        <div class="d-flex align-items-center" style="margin: 0 -1rem">
          <button class="dark-btn mx-3" @click="showAtomDistribute = false" :disabled="distributing">
            <b-spinner small type="grow" v-show="distributing" />
            {{ $t("operation.cancel") }}
          </button>
          <button class="primary-btn mx-3" @click="distribute('atom')" :disabled="distributing">
            <b-spinner small type="grow" v-show="distributing" />
            {{ $t("operation.confirm") }}
          </button>
        </div>
      </div>
    </b-modal>

     <!-- steem tip -->
    <b-modal
      v-model="showSteemDistribute"
      modal-class="custom-modal"
      size="m"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="custom-form font20 line-height28">
        <div class="modal-title font-bold mb-2">
          {{ $t("operation.distribute") }}
        </div>
        <div class="mb-4">
          <div class="label mb-3 text-center">
            <span>
                You will distribute {{ inputSteemAmount }} STEEM to the treasury
            </span>
          </div>
          <div class="label mb-2 d-flex justify-content-between">
            <span></span>&nbsp;
            <span class="text-right">{{ $t("wallet.balance") }}:{{ adminBsteemBalance | amountForm }} STEEM</span>
          </div>
          <div class="c-input-group c-input-group-bg mb-3">
            <input type="number" v-model="inputSteemAmount" placeholder="0"/>
          </div>
          <div class="col-data-container font14 col-data-title mb-1">
            <div class="col-data">
                Community Name
            </div>
            <div class="col-data">
                Percentage
            </div>
            <div class="col-data">
                Distribute Amount
            </div>
          </div>
          <div class="col-data-container" v-for="c of steemData" :key="c.id + 'steem-op'">
            <div class="col-data">
                {{ c.info.name }}
            </div>
            <div class="col-data">
                {{ (totalSteem === 0 ? 0 : c.steemAmount / totalSteem * 100).toFixed(2) }}%
            </div>
            <div class="col-data">
                {{ (totalSteem === 0 ? 0 : c.steemAmount * parseFloat(inputSteemAmount) / totalSteem) | amountForm }}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center" style="margin: 0 -1rem">
          <button class="dark-btn mx-3" @click="showSteemDistribute = false" :disabled="distributing">
            <b-spinner small type="grow" v-show="distributing" />
            {{ $t("operation.cancel") }}
          </button>
          <button class="primary-btn mx-3" @click="distribute('steem')" :disabled="distributing">
            <b-spinner small type="grow" v-show="distributing" />
            {{ $t("operation.confirm") }}
          </button>
        </div>
      </div>
    </b-modal>
    </div>
</template>

<script>
import { getISOPoolsData, getTreasuryTokenBalance, multiSend } from '@/utils/web3/treasury'
import { getPoolType } from '../utils/web3/pool'
import { valBech32ToAddress } from '../utils/cosmos/cosmos'
import { ethers } from 'ethers'
import { mapState, mapGetters } from 'vuex'
import { treasuryTokens } from '@/config'
import { getApprovement, approveUseERC20 } from '../utils/web3/community'
import { contractAddress } from '@/utils/web3/contract'
import { handleApiErrCode } from '../utils/helper'
import { sleep } from '../utils/helper'

export default {
    name: 'Treasury',
    data() {
        return {
            poolsInfo: null,
            atomValidator: 'cosmosvaloper13hule0xx8gvzjrgstqjct0kwygfh7kd03dr93f',
            steemDelegatee: 'terry3t',

            inputAtomAmount: '',
            inputSteemAmount: '',

            atomData: null,
            steemData: null,
             
            showAtomDistribute: false,
            showSteemDistribute: false,

            adminBatomBalance: 0,
            adminBsteemBalance: 0,

            loadingPoolData: true,
            distributing: false,

            isLoadingApproveAtom: true,
            isApprovingAtom: false,
            atomApprovement: false,

            isLoadingApproveSteem: true,
            isApprovingSteem: false,
            steemApprovement: false
        }
    },
    computed: {
        ...mapState("steem", ["vestsToSteem"]),
        ...mapGetters("community", ["getCommunityInfoById"]),
        totalSteem() {
            if (this.steemData) {
                return this.steemData.reduce((a, b) => a + b.steemAmount, 0)
            }
        },
        totalAtom() {
            if (this.atomData) {
                return this.atomData.reduce((a, b) => a + b.atomAmount, 0 )
            }
        }
    },
    methods: {
        search(type) {
            console.log(35,this.poolsInfo);
            if (type === 'atom') {
                 this.atomData = this.poolsInfo.map(c => {
                    let totalAmount = 0
                    for (let pool of c.pools) {
                        if (getPoolType(pool.poolFactory, pool.chainId) === 'atom') {
                            if (pool.asset === valBech32ToAddress(this.atomValidator).toLocaleLowerCase()) {
                                totalAmount += pool.totalAmount.toString() / 1e6
                            }
                        }
                    }
                    c.atomAmount = totalAmount
                    c.info = this.getCommunityInfoById(c.id)
                    return c
                 })
            }else if (type === 'steem') {
                this.steemData = this.poolsInfo.map(c => {
                    let totalAmount = 0
                    for (let pool of c.pools) {
                        if (getPoolType(pool.poolFactory, pool.chainId) === 'steem') {
                            if (ethers.utils.parseBytes32String(pool.asset).toLowerCase() === this.steemDelegatee) {
                                totalAmount += pool.totalAmount.toString() / 1e6 * this.vestsToSteem
                            }
                        }
                    }
                    c.steemAmount = totalAmount
                    c.info = this.getCommunityInfoById(c.id)
                    return c
                })
                
            }
        },
        async approve(type) {
            try{
                if (type === 'atom') {
                    this.isApprovingAtom = true
                    await approveUseERC20(treasuryTokens['batom'], contractAddress['MultiSend'])
                    this.atomApprovement = true
                }else if (type === 'steem') {
                    this.isApprovingSteem = true
                    await approveUseERC20(treasuryTokens['bsteem'], contractAddress['MultiSend'])
                    this.steemApprovement = true
                }
                this.$bvToast.toast('Approve success', {
                    title: 'Success',
                    variant: 'success'
                })
            } catch (e) {
                handleApiErrCode(e, (title, info) => {
                    this.$bvToast.toast(title, info)
                });
            } finally {
                this.isApprovingAtom = false
                this.isApprovingSteem = false
            }
            
        },
        async distribute(type) {
            if (type === 'atom') {
                try {
                    let amount = parseFloat(this.inputAtomAmount)
                    if (this.adminBatomBalance < amount) {
                        this.$bvToast.toast('Insufficient balance', {
                            title: 'Tips',
                            variant: 'info'
                        })
                        return;
                    }
                    let addresses = this.atomData.map(c => c.treasury)
                    let amounts = this.atomData.map(c => ethers.utils.parseUnits((parseFloat(c.atomAmount) / parseFloat(this.totalAtom) * parseFloat(this.inputAtomAmount)).toString(), 18))
                    this.distributing = true
                    await multiSend(treasuryTokens['batom'], addresses, amounts)
                    this.$bvToast.toast('Distribute success', {
                        title: 'Success',
                        variant: 'success'
                    })
                    this.inputAtomAmount = ''
                    await sleep(5)
                    this.showAtomDistribute = false
                } catch(e) {
                    console.log(75, e)
                    handleApiErrCode(e, (title, info) => {
                        this.$bvToast.toast(title, info)
                    });
                } finally {
                    this.distributing = false
                }
            }else if(type === 'steem') {
                try {
                    let amount = parseFloat(this.inputSteemAmount)
                    if (this.adminBsteemBalance < amount) {
                        this.$bvToast.toast('Insufficient balance', {
                            title: 'Tips',
                            variant: 'info'
                        })
                        return;
                    }
                    let addresses = this.steemData.map(c => c.treasury)
                    let amounts = this.steemData.map(c => ethers.utils.parseUnits((parseFloat(c.steemAmount) / parseFloat(this.totalSteem) * parseFloat(this.inputSteemAmount)).toString(), 18))
                    this.distributing = true
                    await multiSend(treasuryTokens['bsteem'], addresses, amounts)
                    this.$bvToast.toast('Distribute success', {
                        title: 'Success',
                        variant: 'success'
                    })
                    this.inputSteemAmount = ''
                    await sleep(5)
                    this.showSteemDistribute = false
                } catch(e) {
                    handleApiErrCode(e, (title, info) => {
                        this.$bvToast.toast(title, info)
                    });
                } finally {
                    this.distributing = false
                }
            }
            this.getBalance()
        },
        getBalance() {
            // get how many trearsury token current admin have
            getTreasuryTokenBalance().then(b => {
                this.adminBatomBalance = b[treasuryTokens['batom']].toString() / 1e18
                this.adminBsteemBalance = b[treasuryTokens['bsteem']].toString() / 1e18
            })
        }
    },
    mounted() {
        getISOPoolsData().then(pools => {
            this.poolsInfo = pools
            this.loadingPoolData = false
        })
        this.getBalance()
        getApprovement(treasuryTokens['batom'], contractAddress['MultiSend']).then(res => {
            this.isLoadingApproveAtom = false
            this.atomApprovement = res
        })
        getApprovement(treasuryTokens['bsteem'], contractAddress['MultiSend']).then(res => {
            this.isLoadingApproveSteem = false
            this.steemApprovement = res
        })
    },
}
</script>

<style lang="scss" scoped>
@import "src/static/css/form";
@import "src/static/css/card/common-card";
@import "src/static/css/card/poster-card";
.col-data{
    flex: 1;
    text-align: center;
}
.col-data-title{
    color: gray;
}
.col-data-container {
    display: flex;
    flex-flow: wrap;
    justify-items:center;
    justify-content: space-between;
}
</style>