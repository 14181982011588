import { render, staticRenderFns } from "./SubCommunityNutPower.vue?vue&type=template&id=fba3715e&scoped=true&"
import script from "./SubCommunityNutPower.vue?vue&type=script&lang=js&"
export * from "./SubCommunityNutPower.vue?vue&type=script&lang=js&"
import style0 from "./SubCommunityNutPower.vue?vue&type=style&index=0&id=fba3715e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fba3715e",
  null
  
)

export default component.exports