<template>
<div class="page-content">
<div class="container h-100">
<div class="sub-community-page">
<div class="sub-page-container">
<div class="sub-home-page">
    <div class="scroll-content page-view">
    <div class="nav-box nav-box-bg mb-3 mb-md-0">
      <div class="nav">
        <span
          v-for="(item, index) of tabOptions"
          :key="index"
          :class="activeTab === index ? 'active' : ''"
          @click="tabChange(index)"
        >
          {{ item }}
        </span>
      </div>
    </div>
    <br />
    <div class="c-card">
        <div class="content3">
            <div class="custom-form form-row-align-center">
                <!-- contract address -->
                <b-form-group label-cols-md="3" content-cols-md="8"
                          label-class="font14"
                          label-align="right"
                          label="Contract:">
                    <div class="d-flex v-middle">
                        <div class="c-input-group c-input-group-bg" style="width: 500px;">
                            <b-form-input v-model="currentCon" style="font-size:0.8rem;"></b-form-input>
                            <span></span>
                        </div>
                    </div>
                </b-form-group>
                <!-- contract nft id -->
                <b-form-group label-cols-md="3" content-cols-md="8"
                          label-class="font14"
                          label-align="right"
                          label="NFT ID:">
                    <div class="d-flex v-middle">
                        <div class="c-input-group c-input-group-bg">
                            <b-form-input v-model="nftId"></b-form-input>
                            <span></span>
                        </div>
                    </div>
                </b-form-group>
                <!-- contract amount -->
                <b-form-group label-cols-md="3" content-cols-md="8"
                          label-class="font14"
                          label-align="right"
                          label="Amount:">
                    <div class="d-flex v-middle">
                        <div class="c-input-group c-input-group-bg">
                            <b-form-input v-model="amount"></b-form-input>
                            <span></span>
                        </div>
                    </div>
                </b-form-group>
                <!-- contract to -->
                <b-form-group label-cols-md="3" content-cols-md="8"
                          label-class="font14"
                          label-align="right"
                          label="To:">
                    <div class="d-flex v-middle">
                        <div class="c-input-group c-input-group-bg">
                            <b-form-input v-model="toAddress"></b-form-input>
                            <span></span>
                        </div>
                    </div>
                </b-form-group>
            </div>
        </div>
        <button class="primary-btn w-50 mt-5" @click="mint">
            Mint
        </button>
    </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
</template>

<script>
import { isErc1155 } from '@/utils/web3/erc1155'
import { getContract } from '@/utils/web3/contract'
export default {
    data () {
        return {
            activeTab: 0,
            tabOptions: ['Ambassador', 'Peanut'],
            contracts:['0xf37b162B98197D3f19eD4c597FEe99BaA71fC0D2','0xfbBC551fEb4CBB691602402edBB74cEE513cAB88'],
            currentCon:"0xf37b162B98197D3f19eD4c597FEe99BaA71fC0D2",
            toAddress:"",
            amount:"",
            nftId:""
        }
    },
    methods: {
        tabChange(index){
            this.activeTab = index;
            this.currentCon = this.contracts[index];
        },
        async mint() {
            let isCon = await isErc1155(this.currentCon);
            if(!isCon){
                this.$bvToast.toast('Address is invalid', {
                    title: 'Address is invalid',
                    variant: 'The contract address is invalid'
                });
                return;
            }
            if(!this.toAddress || !this.amount || !this.nftId){
                this.$bvToast.toast('Params is invalid', {
                    title: 'Params is invalid',
                    variant: 'The params is invalid'
                });
                return;
            }
            let constract = await getContract("ERC1155NFT",this.currentCon,false);
            let res = await constract.mint(this.toAddress,this.nftId,this.amount,'0x00');
            console.log("mint: ",res);
            if(res && 'hash' in res){
                this.$bvToast.toast(`Mint Success: ${res.hash}`, {
                    title: 'Success',
                    variant: 'success'
                })
            }

        }
    },
}
</script>
