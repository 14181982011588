<template>
  <div class="home-page">
    <div class="scroll-content">
      <div class="container">
        Walnut operation system
        <br>
        Only admin can operate the page
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getWalnutData } from '@/utils/graphql/committee'
import CommunityCard from '@/components/community/CommunityCard'
import LineChart from '@/components/common/LineChart'

export default {
  name: 'Home',
  components: { CommunityCard, LineChart },
  data () {
    return {
      loadingAllCommunity: true,
      loading: true,
      loadingTvl:false,
      unlockingPeriod: '0 Days'
    }
  },
  computed: {
    ...mapState(['prices', 'tvl']),
    ...mapState('np',['commonData']),
    ...mapState('web3', ['walnutInfo']),
    ...mapState('community', ['allCommunityInfo']),
    ...mapState('steem', ['vestsToSteem']),
    ...mapState('hive', ['vestsToHive']),
  },
  methods: {
    gotoOfficial() {
      window.open('https://nutbox.io', '_blank')
    }
  },
  async mounted () {
  }
}
</script>

<style scoped lang="scss">
$home-primary-color: #F8B62A;
.home-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("~@/static/images/home-bg1.svg"), url("~@/static/images/home-bg2.png");
  background-repeat: no-repeat;
  background-position: 70% -2rem, 45% 35%;
  background-size: 45%, 50%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.section1 {
  position: relative;
  background-size: 50% 100%, auto 100%;
  background-repeat: no-repeat;
  background-position: left top, right top;
  margin-top: 3rem;
  .s1-card {
    @include card(2.5rem 3rem, transparent);
    border-radius: .8rem 5rem .8rem 5rem;
    background-image: url("~@/static/images/home-s1-bg1.png");
    background-size: 100% 100%;
    background-origin: padding-box;
    background-repeat: no-repeat;
  }
  .text-logo {
    height: 1.2rem;
    width: fit-content;
    object-fit: contain;
    object-position: left;
  }
  .s1-title {
    font-size: 36px;
    line-height: 40px;
    font-weight: bolder;
    width: fit-content;
  }
  .home-img1 {
    position: absolute;
    width: 35%;
    right: 1rem;
    bottom: 0;
  }
  .s1-btn {
    height: 2.4rem;
    padding: 0 2.5rem;
    color: white;
    background-image: linear-gradient(to right, #D252CB, #4826DF);
    border-radius: 3.2rem;
    border: transparent;
    width: fit-content;
    font-size: 1rem;
    font-weight: bolder;
    white-space: nowrap;
    &:hover {
      background-image: linear-gradient(to right, #4826DF, #4826DF);
    }
  }
}
.section2 {
  .s2-card {
    @include card(2.5rem);
    background-origin: padding-box;
    border: none;
    padding-right: 10rem;
    background-size: 100%, 5rem, 7.5rem auto;
    background-repeat: no-repeat;
    background-position: right bottom, 0 0, 95% bottom;
    &-1 {
      background-image:
        linear-gradient(-120deg,rgba(205, 80, 203, 0.3), rgba(78, 40, 222, 0) 20%),
        url("~@/static/images/home-s2-img1.png"),
        url("~@/static/images/home-s2-img2.png");
    }
    &-2 {
      background-image:
        linear-gradient(-120deg,rgba(205, 80, 203, 0.3), rgba(78, 40, 222, 0) 20%),
        url("~@/static/images/home-s2-img1.png"),
        url("~@/static/images/home-s2-img3.png");
    }
  }
  .s2-card2 {
    position: relative;
    @include card(1rem);
    border: transparent;
    box-shadow: 0 1px 2px 0 rgba(white, 0.2) inset;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center bottom;
    &-1 {
      background-image: url("~@/static/images/home-s2-img4.svg");
    }
    &-2 {
      background-image: url("~@/static/images/home-s2-img5.svg");
    }
    &-3 {
      background-image: url("~@/static/images/home-s2-img6.svg");
    }
    .value {
      line-height: 2.8rem;
    }
  }
}
.section3 {
  flex: 1;
  margin-top: 3.2rem;
  .more {
    cursor: pointer;
  }
  .more-text-icon {
    @include icon(1rem, 1rem);
    background-image: url("~@/static/images/right-arrow.svg");
  }
  .card-item {
    width: 308px;
    height: 336px;
  }
}
.s-title {
  background-image:-webkit-linear-gradient(left,#FADDC5,#B6B9F8);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
  width: fit-content;
}
.section-chart {
  .c-card {
    border-radius: .8rem;
    border: none;
    margin-top: 10px;
    &:first-child {
      background-image: linear-gradient(to left, #141414, #1D1E1F);
    }
    &:last-child {
      background-image: linear-gradient(to right, #141414, #1D1E1F);
    }
  }
  .chart-title {
    border-left: 4px solid var(--primary-custom);
    padding-left: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .data-card {
    @include card();
    border: none;
    height: 240px;
    min-height: 200px;
  }
  .data-desc {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 1200px) {
  .section1 {
    .home-img1 {
      bottom: 50%;
      transform: translateY(50%);
    }
  }
}
@media (max-width: 1120px) {
  .section2 .s2-card {
    padding-right: 7.5rem;
  }
}
@media (max-width: 960px) {
  .section2 .s2-card {
    padding-right: 2rem;
    background-size: 100%, 5rem, 5.5rem auto;
  }
  .banner1-sub-title {
    display: flex;
    flex-direction: column;
  }
  .text-dot {
    display: none;
  }
  .section-chart {
    .c-card {
      &:first-child {
        background-image: linear-gradient(to top, #141414, #1D1E1F);
      }
      &:last-child {
        background-image: linear-gradient(to bottom, #141414, #1D1E1F);
      }
    }
  }
}
@media (max-width: 767px) {
  .section1 {
    .home-img1 {
      opacity: .4;
    }
  }
}
@media (max-width: 500px) {
  .section1 .s1-btn {
    padding: 0 10px;
  }
  .section2 {
    .s2-card {
      padding: 1rem 3rem 1rem 1rem;
      font-size: 18px;
      background-position: right bottom, 0 0, right bottom;
    }
  }
}
</style>
